import React from "react";
import { Stack, Box, Typography, Divider } from "@mui/material";
import CategoryRow from "./CategoryRow";
import { Loader } from "../../../shared";

const TemplateCustomTable = ({
  rows,
  handleCustomFieldChange,
  data,
  recipelistQuery,
}) => {
  const quantityRefTest = rows.flatMap((category) =>
    category.subRows.map(() => React.createRef())
  );

  const wastageRefTest = rows.flatMap((category) =>
    category.subRows.map(() => React.createRef())
  );
  return (
    <>
      {recipelistQuery?.isPending ? (
        <Loader />
      ) : (
        <Stack
          sx={{
            overflowY: "auto",
          }}
        >
          {/* Header */}
          <Stack
            direction="row"
            bgcolor="#F7FAFC"
            mt={2}
            ml={1}
            height="34px"
            alignItems="center"
            flexShrink={0} // Prevent shrinking
          >
            <Box width="40%">
              <Typography fontWeight={600} fontSize="12px" ml={2}>
                Product
              </Typography>
            </Box>
            <Box width="20%">
              <Typography fontWeight={600} fontSize="12px">
                Selling Unit
              </Typography>
            </Box>
            <Box width="20%">
              <Typography fontWeight={600} fontSize="12px">
                Quantity
              </Typography>
            </Box>
            <Box width="20%">
              <Typography fontWeight={600} fontSize="12px">
                Wastage
              </Typography>
            </Box>
          </Stack>

          {/* Scrollable Content */}
          <Stack
            sx={{
              flexGrow: 1, // Allow content to grow and take available space
              overflowY: "auto", // Add vertical scroll
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 1,
            }}
          >
            {rows.length ? (
              rows.map((category, categoryIndex) => (
                <CategoryRow
                  key={categoryIndex}
                  category={category}
                  handleCustomFieldChange={handleCustomFieldChange}
                  quantityRefTest={quantityRefTest}
                  wastageRefTest={wastageRefTest}
                  startIndex={rows
                    .slice(0, categoryIndex)
                    .reduce((sum, cat) => sum + cat.subRows.length, 0)}
                  data={data}
                />
              ))
            ) : (
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: "500px",
                  minHeight: "200px",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
                >
                  No data found
                </Typography>
              </Stack>
            )}
          </Stack>

          {/* Footer */}
          <Divider sx={{ my: 1 }} />
        </Stack>
      )}
    </>
  );
};

export default TemplateCustomTable;
