import React from "react";
import { Stack, Box, Typography, Divider } from "@mui/material";
import { InputControl } from "../../../shared";

const CategoryRow = ({
  category,
  handleCustomFieldChange,
  quantityRefTest,
  wastageRefTest,
  startIndex,
  data,
}) => {
  const flattenedSubRows = category?.subRows || [];
  return (
    <Stack spacing={1} padding="8px">
      <Typography
        sx={{
          color: "#1760D2",
          textTransform: "uppercase",
        }}
      >
        {category?.subcategory_name}
      </Typography>

      {flattenedSubRows.map((product, index) => {
        const globalIndex = startIndex + index;
        return (
          <React.Fragment key={product.id}>
            <Stack direction="row" alignItems="center">
              <Box width="40%">
                <Typography>{product.name}</Typography>
              </Box>
              <Box width="20%">
                <Typography>{product.selling_unit_name}</Typography>
              </Box>
              <Box width="20%">
                <InputControl
                  sx={{ selfAlign: "right", width: "134px" }}
                  size="small"
                  type="template"
                  value={product.quantity}
                  inputRef={quantityRefTest[globalIndex]}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const nextWastageInput =
                        wastageRefTest[globalIndex]?.current;
                      if (nextWastageInput) {
                        nextWastageInput.focus();
                      }
                    }
                    if (["e", "+", "-"].includes(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      handleCustomFieldChange(
                        product.id,
                        { target: { value: "" } },
                        "quantity"
                      );
                    }
                  }}
                  onChange={(e) =>
                    handleCustomFieldChange(product.id, e, "quantity", data)
                  }
                  onBlur={(e) => {
                    if (!e.target.value || e.target.value === "") {
                      handleCustomFieldChange(
                        product.id,
                        { target: { value: "0" } },
                        "quantity"
                      );
                    }
                  }}
                  inputProps={{
                    autoComplete: "off",
                    autoCorrect: "off",
                    spellCheck: false,
                  }}
                />
              </Box>
              <Box width="20%">
                <InputControl
                  sx={{ selfAlign: "right", width: "134px" }}
                  size="small"
                  key={`wastage-${product.id}`}
                  type="template"
                  value={product.wastage}
                  inputRef={wastageRefTest[globalIndex]}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const nextQuantityInput =
                        quantityRefTest[globalIndex + 1]?.current;
                      if (nextQuantityInput) {
                        nextQuantityInput.focus();
                      }
                    }
                    if (["e", "+", "-"].includes(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      handleCustomFieldChange(
                        product.id,
                        { target: { value: "" } },
                        "wastage"
                      );
                    }
                  }}
                  onChange={(e) =>
                    handleCustomFieldChange(product.id, e, "wastage", data)
                  }
                  onBlur={(e) => {
                    if (!e.target.value || e.target.value === "") {
                      handleCustomFieldChange(
                        product.id,
                        { target: { value: "0" } },
                        "wastage"
                      );
                    }
                  }}
                  inputProps={{
                    autoComplete: "off",
                    autoCorrect: "off",
                    spellCheck: false,
                  }}
                />
              </Box>
            </Stack>
            {index < flattenedSubRows.length - 1 && <Divider sx={{ my: 1 }} />}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default CategoryRow;
