import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomTabPanel } from "../../../shared";
import arrowIcon from "../../../assets/images/Icons/Vector (9).svg";
import { BatchesView, OtherRecipes } from "../components";
import emptyIcon from "../../../assets/images/Icons/search-table.png";
import { formatWeightWithUnits } from "../../../utils";

export const CommonBatchTabs = ({
  templateDetails,
  optionHandleChangeBatches,
  optionHandleChangeRecipies,
}) => {
  const [value, setValue] = useState(0);
  const [indexColumn, setIndexColumn] = useState(0);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  useEffect(() => {
    if (templateDetails?.data?.other_recipes?.length > 0) {
      setSelectedRecipe(templateDetails?.data?.other_recipes[0]);
    }
  }, [templateDetails?.data?.other_recipes]);
  useEffect(() => {
    if (templateDetails?.data?.batches?.length > 0) {
      setSelectedBatch(templateDetails?.data?.batches[0]);
    }
  }, [templateDetails?.data?.batches]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBatchClick = (item, index) => {
    setIndexColumn(index);
    setSelectedBatch(item);
    optionHandleChangeBatches(item);
  };

  const handleRecipeClick = (item, index) => {
    setIndexColumn(index);
    setSelectedRecipe(item);
    optionHandleChangeRecipies(item);
  };

  const batchesLength = templateDetails?.data?.batches?.length || 0;
  const recipesLength = templateDetails?.data?.other_recipes?.length || 0;

  const getBatchesLengthStyles = () => {
    if (value === 0) {
      return {
        backgroundColor: "white",
        color: "black",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    } else {
      return {
        backgroundColor: "black",
        color: "white",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    }
  };

  const getRecipeLengthStyles = () => {
    if (value === 1) {
      return {
        backgroundColor: "white",
        color: "black",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    } else {
      return {
        backgroundColor: "black",
        color: "white",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    }
  };
  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={5} md={4}>
            <Box
              sx={{
                borderRadius: "4px",
                py: "6px",
                px: "6px",
              }}
            >
              <Tabs
                className="styled-tabs"
                value={value}
                onChange={handleChange}
                sx={{
                  marginLeft: "0",
                }}
              >
                <Tab
                  label={
                    <Stack direction="row" gap="10px">
                      Batches
                      <span style={getBatchesLengthStyles()}>
                        {batchesLength}
                      </span>
                    </Stack>
                  }
                />
                <Tab
                  label={
                    <Stack direction="row" gap="10px">
                      Recipes
                      <span style={getRecipeLengthStyles()}>
                        {recipesLength}
                      </span>
                    </Stack>
                  }
                />
              </Tabs>
            </Box>
            {value === 0 && (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  mt={1}
                  mx={1}
                >
                  <Typography fontWeight={600} fontSize="12px">
                    Recipe Name
                  </Typography>
                  <Typography fontWeight={600} fontSize="12px">
                    Quantity
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    border: "1px solid #DFE8F2",
                    borderRadius: "4px",
                    m: "8px",
                    overflow: "hidden",
                    minHeight: "500px",
                  }}
                >
                  {templateDetails?.data?.batches?.length ? (
                    templateDetails?.data?.batches?.map((item, index) => (
                      <Stack
                        sx={{
                          minHeight: "30px",
                          color: index === indexColumn ? "#FFFFFF" : "#263141",
                          bgcolor:
                            index === indexColumn ? "#1760D2" : "#FFFFFF",
                          padding: "6px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleBatchClick(item, index)}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          flexWrap="wrap"
                        >
                          <Stack direction="row" alignItems="flex-start">
                            <Typography
                              fontWeight={400}
                              fontSize="14px"
                              alignContent="center"
                              sx={{
                                maxWidth: "200px",
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {item?.recipe_name}
                            </Typography>
                          </Stack>
                          <Stack direction="row" gap={1} alignItems="center">
                            <Typography fontSize="12px" fontWeight={400}>
                              {formatWeightWithUnits(
                                item?.total_weight,
                                item?.total_weight_unit,
                                3
                              )}
                            </Typography>
                            <img
                              alt="details"
                              src={arrowIcon}
                              style={{
                                height: "10px",
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    ))
                  ) : (
                    <Stack alignItems="center" gap="8px" mt={20}>
                      <img src={emptyIcon} alt="empty" height={56} width={56} />
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#485364",
                        }}
                      >
                        There are no batches yet!
                      </Typography>
                    </Stack>
                  )}
                </Box>
              </>
            )}

            {value === 1 && (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  mt={1}
                  mx={1}
                >
                  <Typography fontWeight={600} fontSize="12px">
                    Recipe Name
                  </Typography>
                  <Typography fontWeight={600} fontSize="12px">
                    Quantity
                  </Typography>
                </Stack>
                <Box
                  sx={{
                    border: "1px solid #DFE8F2",
                    borderRadius: "4px",
                    m: "8px",
                    overflow: "hidden",
                    minHeight: "500px",
                  }}
                >
                  {templateDetails?.data?.other_recipes?.map((item, index) => (
                    <Stack
                      sx={{
                        minHeight: "30px",
                        color: index === indexColumn ? "#FFFFFF" : "#263141",
                        bgcolor: index === indexColumn ? "#1760D2" : "#FFFFFF",
                        padding: "6px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRecipeClick(item, index)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        flexWrap="wrap"
                      >
                        <Stack direction="row" alignItems="flex-start">
                          <Typography
                            fontWeight={400}
                            fontSize="14px"
                            alignContent="center"
                            sx={{
                              maxWidth: "300px",
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.recipe_name}
                          </Typography>
                        </Stack>
                        <Stack direction="row" gap={1} alignItems="center">
                          <Typography fontSize="12px" fontWeight={400}>
                            {formatWeightWithUnits(
                              item?.total_quantity,
                              item?.selling_unit_name,
                              3
                            )}
                          </Typography>
                          <img
                            alt="details"
                            src={arrowIcon}
                            style={{
                              height: "10px",
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  ))}
                </Box>
              </>
            )}
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ backgroundColor: "white" }} padding="8px">
              <CustomTabPanel value={value} index={0}>
                <BatchesView
                  selectedOptionBatch={selectedBatch}
                  templateDetails={templateDetails}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <OtherRecipes
                  selectedOptionRecipe={selectedRecipe}
                  templateDetails={templateDetails}
                />
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
