import React, { useEffect, useState } from "react";
import {
  CustomTabPanel,
  Loader,
  PageListLayout,
  InputControl,
  CustomeDialog,
} from "../../../shared";
import {
  Avatar,
  Tab,
  Tabs,
  Box,
  Chip,
  Stack,
  TextField,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import {
  BasicDetailsView,
  CostAndPricingView,
  RecipeLabels,
  SuccessDialog,
  TipsAndTricksDetails,
} from "../components";
import { HowToPrepareDetails } from "../components";
import { StoreDetails } from "../components/StoreDetails";
import { ChartSettingsView } from "../components";
import { IngredientsTab } from "../../Ingredients/components/IngredientsTab";
import { useLocation, useParams } from "react-router-dom";
import { useCreateOrEdit, useRecipeDetails } from "../hooks";
import editIcon from "../../../assets/images/Icons/editIcon.png";
import { useNavigate } from "react-router-dom";
import ActiveAndInactiveDialog from "../components/ActiveAndInactiveDialog";
import { useLabelPrinting } from "../../LabelPrinting/hooks/useLabelPrinting";

export const RecipeDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  let { id } = useParams();
  const [search, setSearch] = useState("");
  const { optionsTaxGroup, ingredients, handleSuccessModal } = useCreateOrEdit({
    list: true,
    isCreate: state?.isCreate,
    recipeId: id,
  });

  const {
    ingredientQuery,
    basicDetailsQuery,
    selectStatus,
    updateRecipeStatusMuate,
    isEditable,
    isStatusChange,
    isRecipeTipsAndTricks,
    errorState,
    handleClose,
  } = useRecipeDetails({ recipeId: id, isDetails: true });

  const { isVisible } = useLabelPrinting({ isList: true, recipeId: id });

  const isContentLoading =
    ingredientQuery.isLoading || basicDetailsQuery.isLoading;

  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) => navigate("/recipe")}
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Recipe
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {basicDetailsQuery?.data?.name}
    </Typography>,
  ];

  const handleChange = (event, newValue) => {
    navigate(`/recipe/details/${id}${newValue}`);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.toString().includes("basic" || "status")) {
      setSearch("?basic-details");
    } else if (searchParams.toString().includes("chart-settings")) {
      setSearch("?chart-settings");
    } else if (searchParams.toString().includes("how-to-prepare")) {
      setSearch("?how-to-prepare");
    } else if (searchParams.toString().includes("store")) {
      setSearch("?store");
    } else if (searchParams.toString().includes("cost-pricing")) {
      setSearch("?cost-pricing");
    } else if (searchParams.toString().includes("tips-tricks")) {
      setSearch("?tips-tricks");
    } else if (searchParams.toString().includes("labels")) {
      setSearch("?labels");
    }
  }, [location.search]);

  // Show loader while content is loading
  if (isContentLoading) return <Loader />;

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        headerText={
          <>
            <Stack sx={{ alignItems: "center" }} direction="row" gap="12px">
              <Avatar
                sx={{
                  height: "48px",
                  width: "48px",
                  backgroundColor: "transparent",
                }}
                variant="rounded"
                src={
                  basicDetailsQuery?.data?.image
                    ? basicDetailsQuery?.data?.image
                    : "/static/images/avatar/1.jpg"
                }
              />

              <Typography
                key="3"
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  lineHeight: "29.05px",
                  color: "#111C2B",
                }}
              >
                {basicDetailsQuery?.data?.name}
              </Typography>
              <Chip
                size="small"
                color={
                  basicDetailsQuery?.data?.status === 1 ? "primary" : "gray"
                }
                variant="filled"
                label={
                  basicDetailsQuery?.data?.status === 1 ? "ACTIVE" : "INACTIVE"
                }
              />
            </Stack>
          </>
        }
        rightContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            {isStatusChange && (
              <InputControl
                type="dropdown"
                options={selectStatus?.statusOptions}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={
                  selectStatus?.statusOptions?.find(
                    (option) => option.id === basicDetailsQuery?.data?.status
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Status"
                    sx={{ minWidth: "150px" }}
                  />
                )}
                onChange={(event, option) =>
                  selectStatus.statusValueChangeHandler(option)
                }
                disableClearable
              />
            )}
            {isEditable && (
              <Box
                onClick={(e) => {
                  navigate(`/recipe/edit/${id}`);
                }}
                component="img"
                src={editIcon}
                height={20}
                width={20}
              />
            )}
          </Stack>
        }
        pageContent={
          <React.Fragment>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                px: "0 16px 0 16",
                bgcolor: "#fff",
                gap: "20px",
              }}
            >
              <Tabs
                sx={{ px: "0 16px 0 16", gap: "20px" }}
                value={search}
                onChange={handleChange}
              >
                <Tab label="Basic Details" value={"?basic-details"} />
                <Tab label="Chart Settings" value={"?chart-settings"} />
                <Tab label="How to Prepare" value={"?how-to-prepare"} />
                <Tab label="Stores" value={"?store"} />
                <Tab label="Cost & Pricing" value={"?cost-pricing"} />
                {isRecipeTipsAndTricks && (
                  <Tab label="Tips & Tricks" value={"?tips-tricks"} />
                )}
                {isVisible && <Tab label="Labels" value={"?labels"} />}
              </Tabs>
            </Box>
            <CustomTabPanel value={search} index={"?basic-details"}>
              <TabContent
                ingredientGroups={ingredientQuery?.data?.groups}
                component={
                  <BasicDetailsView
                    basicDetailsQuery={basicDetailsQuery}
                    alternative_uom={ingredientQuery?.data?.alternative_uom}
                    total_weight={Number(ingredientQuery?.data?.total_weight)}
                    total_weight_unit={ingredientQuery?.data?.total_weight_unit}
                    selling_unit_name={ingredientQuery?.data?.selling_unit_name}
                    optionsTaxGroup={optionsTaxGroup}
                  />
                }
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?chart-settings"}>
              <TabContent
                ingredientGroups={ingredientQuery?.data?.groups}
                component={<ChartSettingsView recipeId={id} />}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?how-to-prepare"}>
              <TabContent
                ingredientGroups={ingredientQuery?.data?.groups}
                component={<HowToPrepareDetails recipeId={id} />}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?store"}>
              <TabContent
                ingredientGroups={ingredientQuery?.data?.groups}
                component={<StoreDetails recipeId={id} />}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?cost-pricing"}>
              <TabContent
                ingredientGroups={ingredientQuery?.data?.groups}
                component={
                  <CostAndPricingView
                    recipeId={id}
                    ingredientQuery={ingredientQuery}
                    basicDetailsQuery={basicDetailsQuery}
                    isEditable={isEditable}
                  />
                }
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?tips-tricks"}>
              <TabContent
                ingredientGroups={ingredientQuery?.data?.groups}
                component={<TipsAndTricksDetails recipeId={id} />}
              />
            </CustomTabPanel>
            <CustomTabPanel value={search} index={"?labels"}>
              <TabContent
                ingredientGroups={ingredientQuery?.data?.groups}
                component={
                  <RecipeLabels
                    recipeId={id}
                    basicDetailsQuery={basicDetailsQuery}
                  />
                }
              />
            </CustomTabPanel>
          </React.Fragment>
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            width: "500px",
          },
        }}
        closeIcon={false}
        open={ingredients.isCreateOpen}
        title={
          <Stack direction="row" padding="24px 24px 8px 24px" gap={2}>
            <img src="/icons/ic_success.svg" />
            <Typography alignContent="center" fontWeight={600} fontSize="24px">
              Recipe Created Successfully!
            </Typography>
          </Stack>
        }
        content={
          <SuccessDialog
            handleClose={(e) => {
              handleSuccessModal(false);
            }}
            onChange={(event, option) =>
              selectStatus.statusValueChangeHandler({ id: 1, name: "Active" })
            }
            disabled={updateRecipeStatusMuate?.isPending}
          />
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            minWidth: "500px",
            minHeight: "407px",
          },
        }}
        closeIcon={false}
        open={errorState.isOpen}
        title={
          <Stack
            direction="row"
            justifyContent="space-between"
            position="sticky"
            zIndex={999}
            bgcolor="#fff"
            top={0}
          >
            <Stack direction="row" alignItems="center" ml={2}>
              <img src="/icons/ic_pair_dialog.svg" />
              <Typography
                fontWeight={500}
                variant="body1"
                padding="15px"
                fontSize={20}
              >
                Unable to Change Status
              </Typography>
            </Stack>
          </Stack>
        }
        content={
          <ActiveAndInactiveDialog
            errorState={errorState}
            handleClose={handleClose}
          />
        }
      />
    </>
  );
};

const TabContent = ({ component, ingredientGroups }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        {component}
      </Grid>
      <Grid item xs={4} sx={{ backgroundColor: "#FFFFF" }}>
        <IngredientsTab ingredientGroups={ingredientGroups} />
      </Grid>
    </Grid>
  );
};
