import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormHelperText,
  Stack,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useState } from "react";
import {
  InputControl,
  PageListLayout,
  ServerPaginatedtable,
  Loader,
  DeleteModal,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import checkIcon from "../../../assets/images/Icons/check.svg";
import alertIcon from "../../../assets/images/Icons/alert.svg";
import { useTemplates } from "../hooks/useTemplates";
import { SimpleMenuTempDelete } from "../../../shared/components/SimpleMenuTempDelete";
import { DeleteAlertDialog } from "../components/DeleteAlertDialog";
import { GenerateTemplates } from "./GenerateTemplates";
import { CustomDialogTemplate } from "../../../shared/components/CustomDialogTemplate";
import { CommonTab } from "./CommonTab";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateBasedOnUserType } from "../../../utils";

export const TemplatesList = () => {
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Item: {
          message: "The recipes field is required.",
          required: true,
          rule: (value) => {
            if (value.length <= 0) return false;
            else return true;
          },
        },
      },
    })
  );
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");

  const navigate = useNavigate();
  const {
    listQuery: { isLoading, data },
    listQuery,
    templates,
    onSearchHandler,
    setPagination,
    pagination,
    sorting,
    setSorting,
    disableclearButton,
    onClearFilter,
    selectStore,
    selectStatus,
    selectSchedule,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    confirmDelete,
    storeLists,
    StoreValuesHandler,
    categorylistQuery,
    onSave,
    handleOpenClick,
    updateTableData,
    onChangeFilter,
    handleRecipes,
    state,
    handleOptionChangeRecipe,
    handleOptionChangeRecipeBatch,
    onSearchChange,
    templateDetails,
    batchFilter1,
    handleBack,
    handleCustomTableChange,
    onChange,
    onNameCreateSubmit,
    generateBatch,
    createTemplateName,
    copyBatch,
    searchHandler,
    isCreateVisible,
    isEditable,
    isDeletable,
    isCopyTemplate,
    tableData,
    moduleName,
    search,
    handleCustomFieldChange,
    recipelistQuery,
  } = useTemplates({
    list: true,
    dropdownlist: false,
    isList: true,
  });
  const { filters } = templates;

  if (listQuery.isLoading) {
    return <Loader />;
  }
  const onSubmit = () => {
    if (validator.current.allValid()) {
      onNameCreateSubmit();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const onCreateSubmit = (data) => {
    if (validator.current.allValid()) {
      onSave(data);
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const storeList = (stores) => {
    if (stores) {
      if (stores.length < 3) {
        return stores.map((data) => (
          <Chip size="small" variant="outlined" color="gray" label={data} />
        ));
      } else {
        const limitedChips = [];
        const moreStores = [];
        for (var i = 0; i < stores.length; i++) {
          if (i < 2) {
            var data = stores[i];
            const chip = (
              <Chip size="small" variant="outlined" color="gray" label={data} />
            );
            limitedChips.push(chip);
          } else {
            var data = stores[i];
            moreStores.push(data);
          }
        }
        const moreChip = (
          <Tooltip
            title={
              <React.Fragment>
                {moreStoreTooltipList(moreStores)}
              </React.Fragment>
            }
          >
            <Chip
              size="small"
              variant="outlined"
              style={{ color: "#1760D2" }}
              label={stores.length - 2 + " more stores"}
            />
          </Tooltip>
        );
        limitedChips.push(moreChip);
        return limitedChips;
      }
    }
  };

  const filter = createFilterOptions();

  const moreStoreTooltipList = (stores) => {
    return (
      <ul>
        {stores.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    );
  };

  const columnHelper = createColumnHelper(data);

  const columns = [
    columnHelper.accessor("template_name", {
      id: "name",
      header: () => "Template Name",
    }),

    columnHelper.accessor("batches", {
      id: "no_of_batches",
      header: () => "No Of Batches",
    }),

    columnHelper.accessor("scheduled", {
      header: "Scheduled",
      cell: ({ row }) => {
        let icons, colors, labels;

        if (row.original.scheduled === 1) {
          icons = checkIcon;
          colors = "#0B815A";
          labels = "Scheduled";
        } else {
          icons = alertIcon;
          colors = "error";
          labels = "Not Scheduled";
        }
        return (
          <Stack direction="row" alignItems="center" gap={1}>
            <img src={icons} alt={labels} />
            <Typography color={colors}>{labels}</Typography>
          </Stack>
        );
      },
    }),

    columnHelper.accessor("status", {
      header: "Status",

      cell: ({ row }) => {
        return row?.original.status ? (
          <Chip size="small" color="primary" variant="filled" label="ACTIVE" />
        ) : (
          <Chip size="small" color="gray" label="INACTIVE" />
        );
      },
    }),
    columnHelper.accessor("stores", {
      enableSorting: false,
      header: () => "Stores",
      hidden: isStoreAdmin,
      cell: (props) => {
        const { row } = props;
        return row?.original?.stores.length > 0 ? (
          <Stack direction="row" gap="8px">
            {storeList(row.original.stores)}
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" gap={1}>
            <img src={alertIcon} />
            <Typography color="error">No Store Selected</Typography>
          </Stack>
        );
      },
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            mr={-1}
          >
            {(isCopyTemplate || isEditable || isDeletable) && (
              <SimpleMenuTempDelete
                row={row}
                copyAction={() =>
                  handleOpenClick(true, row.original.template_id)
                }
                handleDeleteModal={() => onDelete(row.original)}
                isEditable={isEditable}
                isDeletable={isDeletable}
                isCopyTemplate={isCopyTemplate}
              />
            )}
            <img alt="details" src="/icons/arrow_forward.png" />
          </Stack>
        );
      },
    }),
  ];
  const breadcrumbs = [
    <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Template
    </Typography>,
  ];

  return (
    <>
      {validator.current.purgeFields()}
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <ServerPaginatedtable
                defaultData={listQuery?.data?.templates}
                columns={columns}
                headerText="Template"
                search={search}
                sorting={sorting}
                setSorting={(callback) =>
                  setSorting({ tableName: moduleName, callback })
                }
                pagination={pagination}
                setPagination={(callback) =>
                  setPagination({ tableName: moduleName, callback })
                }
                paginationProps={
                  listQuery.data?.pagination || { total: 0, lastPage: 1 }
                }
                onSearchChange={onSearchHandler}
                isLoading={isLoading}
                onRowLink={(e) => {
                  return `details/${e.template_id}`;
                }}
                tableHeaderContent={
                  <>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "6px",
                        width: "100%",
                        height: "52px",
                      }}
                    >
                      <InputControl
                        type="dropdown"
                        options={selectStatus.statusOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        sx={{
                          width: "180px",
                          height: "36px",
                        }}
                        value={
                          selectStatus?.statusOptions?.find(
                            (option) => option.id === templates.selectedStatus
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Status" />
                        )}
                        onChange={(event, option) =>
                          selectStatus.statusValueChangeHandler(option)
                        }
                      />
                      {!isStoreAdmin && (
                        <InputControl
                          type="dropdown"
                          fullWidth
                          multiple={true}
                          options={storeLists?.data || []}
                          getOptionLabel={(option) => option?.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          disableCloseOnSelect
                          sx={{
                            width: "230px",
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => <></>)
                          }
                          value={filters?.storefilter}
                          onChange={(event, option) =>
                            StoreValuesHandler(event, option)
                          }
                          renderOption={(props, option, { selected }) => (
                            <>
                              <li {...props} key={option.id}>
                                <Checkbox
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            </>
                          )}
                          filterOptions={filter}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Store" />
                          )}
                        />
                      )}
                      <InputControl
                        type="dropdown"
                        options={selectSchedule?.sceduleOptions}
                        value={
                          selectSchedule?.sceduleOptions?.find(
                            (scedule) =>
                              scedule?.id === templates?.selectedSchedule
                          ) || null
                        }
                        getOptionLabel={(option) => option?.name || ""}
                        onChange={(event, option) =>
                          selectSchedule.sceduleValueChangeHandler(option)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        sx={{
                          width: "230px",
                          gap: "8px",
                        }}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Scheduled" />
                        )}
                      />
                      <Button
                        type="button"
                        size="small"
                        variant="text"
                        sx={{
                          p: "6px 12px 6px 12px",
                          gap: "6px",
                        }}
                        disabled={disableclearButton}
                        onClick={onClearFilter}
                      >
                        Clear All
                      </Button>
                    </Stack>
                  </>
                }
                rightContent={
                  <Stack direction="row" gap="8px">
                    {isCreateVisible && (
                      <Button
                        sx={{
                          height: "36px",
                          border: "1px solid #BCC7DB",
                          borderRadius: "10px",
                        }}
                        type="button"
                        size="medium"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenClick(true)}
                      >
                        New Template
                      </Button>
                    )}
                    <CustomDialogTemplate
                      open={templates.isOpen}
                      // handleClose={() => handleOpenClick(false)}
                      handleBack={() => handleBack()}
                      PaperProps={{
                        sx: {
                          minWidth: "993px",
                          maxWidth: "100%",
                          height: "750px",
                          display: "flex",
                          flexDirection: "column",
                          overflow: "hidden",
                        },
                      }}
                      title={
                        !state.isCreate ? (
                          templateDetails?.data?.template_name ? (
                            templateDetails?.data?.template_name
                          ) : (
                            "Generate Template"
                          )
                        ) : (
                          <InputControl
                            label="Template Name"
                            name="template_name"
                            required
                            onChange={onChange}
                            disabled={state.isDuplicate}
                            sx={{ width: "405px" }}
                            error={validator.current.message(
                              "template Name",
                              templates.data.template_name,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Template Name",
                              templates.data.template_name,
                              "required"
                            )}
                          />
                        )
                      }
                      isBackArroaw={state.isCreate}
                      content={
                        !state.isCreate ? (
                          <GenerateTemplates
                            templates={templates}
                            categorylistQuery={categorylistQuery}
                            handleRecipes={handleRecipes}
                            onChangeFilter={onChangeFilter}
                            updateTableData={updateTableData}
                            onSearchChange={onSearchChange}
                            handleCustomTableChange={handleCustomTableChange}
                            searchHandler={searchHandler}
                            handleCustomFieldChange={handleCustomFieldChange}
                            recipelistQuery={recipelistQuery}
                          />
                        ) : (
                          <CommonTab
                            state={state.templateDetails}
                            templates={templates}
                            handleOptionChangeRecipeBatch={
                              handleOptionChangeRecipeBatch
                            }
                            handleOptionChangeRecipe={handleOptionChangeRecipe}
                          />
                        )
                      }
                      tableFooter={
                        !state.isCreate ? (
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={2}
                            padding="10px"
                            marginTop="auto"
                          >
                            <Box sx={{ alignContent: "center" }}>
                              <FormHelperText sx={{ color: "#C60808" }}>
                                {validator.current.message(
                                  "Recipe field",
                                  batchFilter1,
                                  "Item"
                                )}
                              </FormHelperText>
                            </Box>
                            <Button
                              key="cancel"
                              variant="outlined"
                              onClick={() => handleOpenClick(false)}
                            >
                              Cancel
                            </Button>

                            <Button
                              key="generate"
                              variant="contained"
                              disabled={
                                generateBatch?.isPending || copyBatch?.isPending
                              }
                              onClick={() => {
                                onCreateSubmit(templates.updateValue);
                              }}
                            >
                              Generate Batches
                            </Button>
                          </Stack>
                        ) : (
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={2}
                            padding="10px"
                          >
                            <Button
                              key="cancel"
                              variant="outlined"
                              onClick={() => handleOpenClick(false)}
                            >
                              Cancel
                            </Button>

                            <Button
                              key="generate"
                              variant="contained"
                              disabled={createTemplateName?.isPending}
                              onClick={() => {
                                state.isDuplicate
                                  ? navigateBasedOnUserType(
                                      `/templates/details/${state?.templateDetails?.data?.template_id}`,
                                      isStoreAdmin,
                                      navigate
                                    )
                                  : onSubmit();
                              }}
                            >
                              Continue
                            </Button>
                          </Stack>
                        )
                      }
                    />
                  </Stack>
                }
              />
            </Stack>
          </>
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />

      <DeleteAlertDialog
        id={deleteInfo.id}
        open={deleteInfo.showTemp}
        handleClose={() => resetDeleteInfo()}
        isStoreAdmin={isStoreAdmin}
      />
    </>
  );
};
