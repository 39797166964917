import { Stack, Modal, Typography, Button, Box } from "@mui/material";
import React from "react";
import warning_icon from "../../../assets/images/Icons/icAlert.svg";

const style = {
  position: "absolute",
  top: "65%",
  left: "50%",
  transform: "translate(-50%, -100%)",
  width: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "16px",
  outline: "none",
};
export const LabelPrintDeleteAlertDialog = (props) => {
  const { open, handleClose, onConfirm, state } = props;
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Stack
            direction="row"
            gap="10px"
            alignItems="center"
            lineHeight="34.2px"
            marginBottom={3}
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "35px",
                bgcolor: "#F044381F",
              }}
            >
              <img src={warning_icon} style={{ padding: "6px" }} />
            </Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "25px",
                lineHeight: "24.2px",
              }}
            >
              Delete Item?
            </Typography>
          </Stack>
          <Typography id="modal-modal-title" fontWeight="400" fontSize="16px">
            Would you like to delete item “{state}”?
          </Typography>

          <Stack direction="row" gap="12px" justifyContent="flex-end" mt={3}>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>

            <Button onClick={onConfirm} variant="contained" color="error">
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};
