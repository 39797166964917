import { Box, InputAdornment, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import { InputControl, SelectChip } from "../../../shared";
import CloseIcon from "@mui/icons-material/Close";
import SimpleReactValidator from "simple-react-validator";
import TemplateCustomTable from "../components/TemplateCustomTable";

export const GenerateTemplates = ({
  categorylistQuery,
  templates,
  onChangeFilter,
  onSearchChange,
  searchHandler,
  handleCustomFieldChange,
  recipelistQuery,
}) => {
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const categoryRef = useRef(null);
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <>
      <Stack direction="row" gap="10px" alignItems="center" ml={3}>
        <InputControl
          sx={{ width: "200px", borderRadius: "10px" }}
          type="table-search"
          placeholder="Search"
          onChange={(e) =>
            onSearchChange(e.target.value, templates.groupedDatas)
          }
          value={templates.filter.search}
          endAdornment={
            templates.filter.search ? (
              <InputAdornment sx={{ cursor: "pointer" }} position="end">
                <CloseIcon size="large" onClick={() => searchHandler("")} />
              </InputAdornment>
            ) : (
              ""
            )
          }
        />
        <Box
          height="45px"
          alignContent="center"
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, -10)
          }
        >
          <img alt="details" src="/icons/Intersect.png" />
        </Box>
        <Stack
          direction="row"
          width="725px"
          overflow="auto"
          gap={1}
          className="scroll-hide"
          ref={categoryRef}
        >
          {categorylistQuery?.data?.map((v, i) => (
            <SelectChip
              key={i}
              value={v}
              isSelected={
                templates.filter.category
                  ? templates.filter.category === v.id
                  : i === 0
              }
              onClick={() => onChangeFilter(v, templates.groupedDatas)}
            />
          ))}
        </Stack>
        <Box
          height="45px"
          alignContent="center"
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, 10)
          }
        >
          <img alt="details" src="/icons/Intersect1.png" />
        </Box>
      </Stack>

      <TemplateCustomTable
        rows={templates.templateResultRows}
        handleCustomFieldChange={handleCustomFieldChange}
        data={templates}
        recipelistQuery={recipelistQuery}
      />
    </>
  );
};
