import { Button, Stack } from "@mui/material";
import React from "react";
import {
  CustomeDialog,
  DeleteModal,
  ServerPaginatedtable,
  SimpleMenu,
} from "../../../shared";
import { RecipeLabelCreateOrEdit } from "../../Recipe/components/RecipeLabelCreateOrEdit";
import { createColumnHelper } from "@tanstack/react-table";
import { useLabelPrinting } from "../../LabelPrinting/hooks/useLabelPrinting";
import { convertStringToTime } from "../../../utils";

export const IngredientsLabels = ({ ingredientId, ingredient }) => {
  const {
    labelPrinting,
    listQuery,
    handleCreateOrEditModal,
    onCreateSubmit,
    onChange,
    onDelete,
    confirmDelete,
    resetDeleteInfo,
    deleteInfo,
    globalData,
    ingredientListQuery,
    recipeListQuery,
    onEditChange,
    moduleSorting,
    modulePagination,
    setModulePagination,
    setModuleSorting,
    validator,
    isCreateVisible,
    isEditable,
    isDeletable,
    labelPrintingDetails,
    labelPrintingRecipeEditChangeHandler,
  } = useLabelPrinting({
    isList: true,
    ingredientId,
    type_id: 2,
    item_id: ingredientId,
    isLabelprintingList: false,
  });
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("variant_name", {
      header: "Variant",
    }),
    columnHelper.accessor("defrosting_time", {
      header: "Defrosting Time Hrs",
      cell: (props) => {
        const { row } = props;
        return convertStringToTime(row.original.defrosting_time, "minute")
      },
    }),
    columnHelper.accessor("freezer_life", {
      header: "Coolroom/Freezer Life Hrs",
      cell: (props) => {
        const { row } = props;
        return convertStringToTime(row.original.freezer_life, "minute")
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            mr={-1}
          >
            <SimpleMenu
              row={row}
              editAction={(e) => handleCreateOrEditModal(true, e.id)}
              handleDeleteModal={(e) => onDelete(e.id)}
              isEditable={isEditable}
              isDeletable={isDeletable}
            />
          </Stack>
        );
      },
    }),
  ];
  return (
    <>
      <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
        <ServerPaginatedtable
          defaultData={listQuery.data?.label_printing_items ?? []}
          columns={columns}
          headerText="Label Printing"
          sorting={moduleSorting}
          setSorting={setModuleSorting}
          pagination={modulePagination}
          setPagination={setModulePagination}
          paginationProps={
            listQuery.data?.pagination || { total: 0, lastPage: 1 }
          }
          isLoading={listQuery?.isLoading}
          rightContent={
            <Stack direction="row" gap="8px">
              {isCreateVisible && (
                <Button
                  sx={{
                    height: "36px",
                    border: "1px solid #BCC7DB",
                    borderRadius: "10px",
                    borderColor: "#BCC7DB",
                    color: "#111C2B",
                  }}
                  type="button"
                  size="medium"
                  variant="outlined"
                  onClick={() => handleCreateOrEditModal(true)}
                >
                  Create Label
                </Button>
              )}
            </Stack>
          }
        />
      </Stack>
      <CustomeDialog
        open={labelPrinting.openDialog}
        PaperProps={{
          sx: { width: "482px", minHeight: "400px", p: "16px" },
        }}
        title={labelPrinting.id ? "Edit Label" : "Create Label"}
        content={
          <RecipeLabelCreateOrEdit
            handleCreateOrEditModal={handleCreateOrEditModal}
            basicDetailsQuery={ingredient}
            onCreateSubmit={onCreateSubmit}
            onChange={onChange}
            labelPrinting={labelPrinting}
            isLabelprintingList={false}
            recipeId={ingredientId}
            globalData={globalData}
            recipeListQuery={recipeListQuery}
            ingredientListQuery={ingredientListQuery}
            onEditChange={onEditChange}
            validator={validator}
            labelPrintingDetails={labelPrintingDetails}
            labelPrintingRecipeEditChangeHandler={
              labelPrintingRecipeEditChangeHandler
            }
          />
        }
      />
      <DeleteModal
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
      />
    </>
  );
};
