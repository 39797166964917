import {
  useMutation,
  useQueryClient,
  useQuery,
  keepPreviousData,
} from "@tanstack/react-query";
import { errorMessage, successMessage } from "../../../utils";
import {
  editBasicDetails,
  getBasicDetails,
  saveBasicdetails,
  saveTumpnailImageUrl,
} from "../api/BasicDetailsApi";
import {
  editIngredients,
  getIngredients,
  getIngredientsList,
  getPluList,
  getUnitsList,
  saveIngredients,
} from "../api/IngredientsApi";
import {
  editChartSettings,
  getChartSettings,
  saveChartSettings,
} from "../api/ChartSettingsApi";
import {
  editHowToPrepare,
  getPreparationDetails,
  saveImageVideoUrl,
  saveRecipePreapration,
} from "../api/HowtoPrepareApi";
import { useNavigate } from "react-router-dom";
import { getTaxGroupList } from "../../Ingredients/api/IngredientApi";

//basic details keys
const basicDetailsKeys = {
  all: ["basicDetails"],
  lists: () => [...basicDetailsKeys.all, "list"],
  detail: (id) => [...basicDetailsKeys.lists(), id],
};

//ingredients list keys
const listKeys = {
  all: ["ingredients"],
  ingredientslists: () => [...listKeys.all, "ingredientslist"],
  detail: (id) => [...listKeys.ingredientslists(), id],
};

// all units keys
const unitKeys = {
  all: ["units"],
  altunitlists: () => [...unitKeys.all, "altunitlist"],
  detail: (id) => [...unitKeys.altunitlists(), id],
};

//chart settings keys
const chartSettingsKeys = {
  all: ["chartSettings"],
  lists: () => [...chartSettingsKeys.all, "list"],
  detail: (id) => [...chartSettingsKeys.lists(), id],
};

//preparation keys
const recipeKeys = {
  all: ["recipePreparation"],
  lists: () => [...recipeKeys.all, "list"],
  detail: (id) => [...recipeKeys.lists(), id],
};
//plu keys
const pluKeys = {
  all: ["plu"],
  plulist: () => [...pluKeys.all, "plulist"],
  detail: (id) => [...pluKeys.plulist(), id],
};

const ingredientKeys = {
  all: ["ingredients"],
  lists: () => [...ingredientKeys.all, "ingredientlist"],
  ingredientlist: (filters) => [...ingredientKeys.lists(), filters],
  detail: (id) => [...ingredientKeys.ingredientlist(), id],
};
export const useCreateOrEditQueries = ({
  ingredientslist,
  altunitlist,
  detailsId,
  isIngredientsApiEnabled,
}) => {
  //ingredients keys
  const ingredientsKeys = {
    all: ["addingredients"],
    lists: () => [...ingredientsKeys.all, "list"],
    detail: (id) => [...ingredientsKeys.lists(), id],
  };
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // create basic details
  const createBasicDetails = useMutation({
    mutationFn: saveBasicdetails,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      const { id, type } = data;
      const url =
        type === "finishedproduct"
          ? `/recipe/create?tab=ingredients&type=${type}`
          : `/recipe/create?tab=ingredients`;
      variables.handleChange(url, id);
      queryClient.invalidateQueries({
        queryKey: basicDetailsKeys.lists(),
      });
      successMessage("Basic recipe details stored successfully!");
    },
  });

  //create imageUrl for basic details
  const createImageUrl = useMutation({
    mutationFn: saveTumpnailImageUrl,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
  });

  // update basic details
  const updateBasicDetails = useMutation({
    mutationFn: editBasicDetails,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: basicDetailsKeys.lists(),
      });
      // variables.handleChange(
      //   `/recipe/edit/${variables.detailsId}?tab=ingredients`
      // );
      successMessage("Basic recipe details updated successfully!");
    },
  });

  // get BasicDetails details
  const basicDetailsData = useQuery({
    queryKey: basicDetailsKeys.detail(detailsId),
    queryFn: () => getBasicDetails(detailsId),
    enabled: detailsId > 0,
  });

  // ingredients list query
  const listQuery = useQuery({
    queryKey: listKeys.ingredientslists(),
    queryFn: getIngredientsList,
    enabled: ingredientslist,
  });

  //alt unit list query
  const altunitListQuery = useQuery({
    queryKey: unitKeys.altunitlists(),
    queryFn: getUnitsList,
    enabled: altunitlist,
  });

  // create ingredients
  const createIngredients = useMutation({
    mutationFn: saveIngredients,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      variables.handleChange(`/recipe/create?tab=settings`, variables.createId);
      queryClient.invalidateQueries({
        queryKey: ingredientsKeys.lists(),
      });
      successMessage("Recipe groups and ingredients stored successfully!");
    },
  });

  // update Ingredients
  const updateIngredients = useMutation({
    mutationFn: editIngredients,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ingredientsKeys.lists(),
      });
      // variables.handleChange(
      //   `/recipe/edit/${variables.detailsId}?tab=settings`
      // );
      successMessage("Recipe groups and ingredients updated successfully!");
    },
  });

  // get Ingredients details
  const ingredientsDetails = useQuery({
    queryKey: ingredientsKeys.detail(detailsId),
    queryFn: () => getIngredients(detailsId),
    enabled: isIngredientsApiEnabled && detailsId > 0,
  });

  // create chart settings
  const createChartSettings = useMutation({
    mutationFn: saveChartSettings,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      variables.handleChange(`/recipe/create?tab=prepare`, variables.createId);
      queryClient.invalidateQueries({
        queryKey: chartSettingsKeys.lists(),
      });
      successMessage("Chart settings created successfully!");
    },
  });

  // update ChartSettings
  const updateChartSettings = useMutation({
    mutationFn: editChartSettings,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: chartSettingsKeys.lists(),
      });
      // variables.handleChange(`/recipe/edit/${variables.detailsId}?tab=prepare`);
      successMessage("Chart settings updated successfully!");
    },
  });

  // get ChartSettings details
  const chartSettingsDetails = useQuery({
    queryKey: chartSettingsKeys.detail(detailsId),
    queryFn: () => getChartSettings(detailsId),
    enabled: detailsId > 0,
  });

  // create recipe
  const createRecipePreapration = useMutation({
    mutationFn: saveRecipePreapration,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: recipeKeys.lists(),
      });
      navigate(`/recipe/details/${data.id}/?basic-details/${"status"}`);
    },
  });

  // update HowToPrepare
  const updatePreparationSteps = useMutation({
    mutationFn: editHowToPrepare,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: recipeKeys.lists(),
      });
      navigate(`/recipe/details/${variables.detailsId}/?basic-details`, {
        replace: true,
      });
      successMessage("Recipe steps and videos updated successfully!");
    },
  });

  // get PreparationSteps details
  const preparationStepsDetails = useQuery({
    queryKey: recipeKeys.detail(detailsId),
    queryFn: () => getPreparationDetails(detailsId),
    enabled: detailsId > 0,
  });

  // create recipe image and video url
  const createImageVideoUrl = useMutation({
    mutationFn: saveImageVideoUrl,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
  });

  const createVideoUrl = useMutation({
    mutationFn: saveImageVideoUrl,
    onError: (error) => {
      errorMessage(error.response.data.message);
    },
  });

  //plu code list

  const pluListQuery = useQuery({
    queryKey: pluKeys.plulist(),
    queryFn: getPluList,
  });

  //tax group list

  const taxListQuery = useQuery({
    queryKey: ingredientKeys.ingredientlist(),
    queryFn: () => getTaxGroupList(),
    placeholderData: keepPreviousData,
  });
  return {
    createBasicDetails,
    createIngredients,
    listQuery,
    altunitListQuery,
    createChartSettings,
    createRecipePreapration,
    createImageUrl,
    createImageVideoUrl,
    createVideoUrl,
    updateBasicDetails,
    basicDetailsData,
    updateChartSettings,
    chartSettingsDetails,
    updateIngredients,
    ingredientsDetails,
    updatePreparationSteps,
    preparationStepsDetails,
    pluListQuery,
    taxListQuery,
    ingredientsKeys,
  };
};
