import { Box, Divider, Grid, Icon, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import IngrdientIcon from "../../../assets/images/Icons/ingredient.svg";
import RecipeIcon from "../.../../../../assets/images/Icons/recipeIcon.svg";
import IngregrientDisabled from "../../../assets/images/Icons/ingredientDisabled.svg";
import RecipeDisabledIcon from "../../../assets/images/Icons/ic_recipe_disabled.svg";
import { formatWeightWithUnits } from "../../../utils";

export const IngredientsGroup = ({ ingredientGroup }) => {
  const isGroupDisabled = ingredientGroup.ingredients.every(
    (item) => !item.status
  );

  const navigate = useNavigate();

  const getTypeIcon = (type, status) => {
    switch (type) {
      case "ingredient":
        return status ? IngrdientIcon : IngregrientDisabled;
        break;
      case "recipe":
        return status ? RecipeIcon : RecipeDisabledIcon;
        break;
      default:
        <></>;
    }
  };
  const ingredientTag = (is_main_ingredient, is_batch_group, status) => {
    return (
      <>
        {is_main_ingredient || is_batch_group || !status ? (
          <Stack direction="row" gap={1} mt={1}>
            <ul className="ingredient-list">
              {is_batch_group ? (
                <li>
                  <Typography
                    sx={{
                      color: status ? "#485364" : "#8E99AC",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14.52px",
                    }}
                  >
                    Batch-Group
                  </Typography>
                </li>
              ) : null}
              {is_main_ingredient ? (
                <li>
                  <Typography
                    sx={{
                      color: status ? "#B42318" : "#8E99AC",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "14.52px",
                    }}
                  >
                    Main Ingredient
                  </Typography>
                </li>
              ) : null}{" "}
              {!status ? (
                <li>
                  <Typography
                    sx={{
                      color: "#0E7090",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "14.52px",
                    }}
                  >
                    Inactive
                  </Typography>
                </li>
              ) : null}
            </ul>
          </Stack>
        ) : null}
      </>
    );
  };

  return (
    <Stack
      sx={{
        backgroundColor: "#F7FAFC",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "14.52px",
          p: "10px 8px 10px 8px",
          height: "34px",
        }}
      >
        {ingredientGroup?.name}
      </Typography>

      <Box
        sx={{
          border: "1px solid #DFE8F2",
          borderRadius: "4px",
          boxShadow: "#000000",
        }}
      >
        {ingredientGroup.ingredients.map((ingredient) => {
          return (
            <>
              <Grid
                container
                sx={{
                  backgroundColor: "white",
                  p: "8px",
                  cursor: ingredient.status ? "pointer" : "",
                  "&:hover": {
                    backgroundColor: ingredient.status ? "#0000001F" : "",
                  },
                }}
                onClick={(e) => {
                  navigate(
                    ingredient.type === "ingredient"
                      ? `/ingredients/details/${ingredient.ingredient_id}`
                      : `/recipe/details/${ingredient.ingredient_id}?basic-details`
                  );
                }}
              >
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    width="18"
                    height="18"
                    component={"img"}
                    src={getTypeIcon(ingredient.type, ingredient.status)}
                  />
                </Grid>
                <Grid item xs={7} SX>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "14.52px",
                      color: ingredient.status ? "#8E99AC" : "#8E99AC",
                    }}
                  >
                    {ingredient.name}
                  </Typography>
                  {ingredientTag(
                    ingredient.is_main_ingredient,
                    ingredient.is_batch_group,
                    ingredient.status
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    width: "100%",
                  }}
                >
                  <Grid container justifyContent="flex-end">
                    <Typography
                      sx={{
                        float: "right",
                        color: "#1760D2",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "16.94px",
                        color: ingredient.status ? "" : "#8E99AC",
                      }}
                    >
                      <span>
                        {formatWeightWithUnits(
                          ingredient.base_unit_qty,
                          ingredient.base_unit_symbol,
                          4
                        )}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
            </>
          );
        })}

        <Stack
          sx={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            backgroundColor: "#E6EEF7",
            height: "34px",
            p: "10px 8px 10px 8px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "14.52px",
              color: isGroupDisabled ? "#8E99AC" : "",
            }}
          >
            Total Weight
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "14.52px",
              color: isGroupDisabled ? "#8E99AC" : "",
            }}
          >
            {formatWeightWithUnits(
              ingredientGroup?.total_qty,
              ingredientGroup?.total_qty_unit,
              3
            )}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};
