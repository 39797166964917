import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import parse from "html-react-parser";

export const getToken = () => {
  let token = "";
  var localData = JSON.parse(localStorage.getItem("recipe-user-data"));
  token = localData?.token;
  return token;
};

export const getStoreId = () => {
  const isStoreAdmin = window.location.pathname.includes("/store-admin");
  const localData = JSON.parse(localStorage.getItem("recipe-user-data"));
  return isStoreAdmin ? localData?.selectedStoreId : null;
};

export const successMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/success_toast.png",
    style: {
      background: "#388e3c",
    },
  }).showToast();
};
export const successRedMessage = async (text) => {
  if (text === null || text === undefined || text === "")
    text = "Data saved successfully!";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/icon_not.svg",
    style: {
      background: "#FEB019",
    },
  }).showToast();
};
export const infoMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#FEB019",
    },
  }).showToast();
};

export const errorMessage = async (text) => {
  if (text === null || text === undefined || text === "") text = "Error";
  Toastify({
    text: text,
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    avatar: "/icons/error_toast.png",
    style: {
      background: "#d32f2f",
    },
  }).showToast();
};
export const ErrorFormatter = (error) => {
  console.log("error", error);
  let errorString = "";

  if (error) {
    for (const field in error) {
      if (Array.isArray(error[field])) {
        error[field].forEach((err) => {
          errorString += `${field}: ${err}</br>`;
        });
      } else {
        errorString += `${field}: ${error[field]}</br>`;
      }
    }
  }

  if (error.response) {
    if (error.response.data.message) {
      errorString += `${error.response.data.message}</br>`;
    }

    const errors = error.response.data.errors;
    if (errors) {
      for (const field in errors) {
        if (Array.isArray(errors[field])) {
          errors[field].forEach((err) => {
            errorString += `${field}: ${err}</br>`;
          });
        } else {
          errorString += `${field}: ${errors[field]}</br>`;
        }
      }
    }
  }
  // else {
  //   errorString += "An error has occurred!";
  // }

  return parse(errorString);
};

export const arraySort = (data, key) => {
  return [...data].sort(function (a, b) {
    return a[key]?.toLowerCase()?.localeCompare(b[key]?.toLowerCase());
  });
};

export const navigateBasedOnUserType = (
  url = "",
  isStoreAdmin = false,
  navigate
) => {
  return navigate(isStoreAdmin ? `/store-admin${url}` : url);
};

// Helper function to convert weight and unit
const convertWeightAndUnit = (weightInGrams, unitSymbol) => {
  if (weightInGrams >= 1000 && (unitSymbol === "gm" || unitSymbol === "ml")) {
    return {
      weight: weightInGrams / 1000,
      unit: unitSymbol === "gm" ? "kg" : "ltr",
    };
  } else if (
    weightInGrams < 1 &&
    (unitSymbol === "kg" || unitSymbol === "ltr")
  ) {
    return {
      weight: weightInGrams * 1000,
      unit: unitSymbol === "kg" ? "gm" : "ml",
    };
  } else {
    return { weight: weightInGrams, unit: unitSymbol };
  }
};

// Helper function to format the decimal part of the weight
const formatDecimalPart = (weight, roundoffValue) => {
  const [wholePart, decimalPart] = weight.toString().split(".");
  if (!decimalPart) return `${wholePart}`; // No decimal part

  let wholePartValue = `${wholePart}.${decimalPart.slice(0, 4).padEnd(4, "0")}`;
  const formattedWeight = roundoffValue
    ? Number(wholePartValue).toFixed(roundoffValue)
    : wholePartValue;

  return formattedWeight;
};

// Helper function to trim trailing zeros in decimal part
const trimTrailingZeros = (formattedWeight) => {
  if (!formattedWeight.includes(".")) return formattedWeight; // No decimal part

  const [wholePart, decimalPart] = formattedWeight.split(".");
  if (decimalPart.endsWith("0")) {
    return Number(formattedWeight).toFixed(3); // Trim to 3 decimals if trailing zeros
  }
  return formattedWeight; // Return as is if no trailing zeros
};

// Main function
export const formatWeightWithUnits = (
  weightInGram,
  unitSymbol,
  roundoffValue
) => {
  const { weight, unit } = convertWeightAndUnit(
    Number(weightInGram),
    unitSymbol
  );
  const formattedWeight = formatDecimalPart(weight, roundoffValue);
  const finalWeight = trimTrailingZeros(formattedWeight);
  return `${finalWeight} ${unit}`;
};

export function convertStringToTime(input, type = null) {
  // Split input if it contains a colon (e.g., 3333:83)
  let [hoursPart, minutesPart] = String(input).split(":");

  //if input is empty , return 00:00
  if (!input) {
    return `00:00`;
  }

  // If minutesPart is undefined, treat the input as a pure number
  if (type === "minute") {
    // If no colon is present, treat input as a pure number in minutes
    const totalMinutes = parseInt(hoursPart, 10);

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Format hours and minutes
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
  }
  // If minutesPart is undefined, treat the input as a pure number
  if (!minutesPart) {
    const length = hoursPart.length;

    if (length === 1 || length === 2) {
      // For inputs with 1 or 2 digits, treat them as minutes
      minutesPart = hoursPart;
      hoursPart = "0";
    } else {
      // For inputs with 3 or more digits, split appropriately
      minutesPart = hoursPart.slice(-2); // Last two digits are minutes
      hoursPart = hoursPart.slice(0, -2) || "0"; // Remaining digits are hours
    }
  }

  // Convert to integers
  let hours = parseInt(hoursPart, 10);
  let minutes = parseInt(minutesPart, 10);

  // Handle minutes overflow
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes %= 60;
  }

  // Format hours and minutes
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export function convertTimeToMinute(time) {
  const [hours, minutes] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
}
