import { useImmer } from "use-immer";
import { useEffect, useState } from "react";
import { dataList, detailsData } from "./dummydata";
import { useProductionChartQuery } from "./useProductionChartQuery";
import { useDebounce } from "use-debounce";
import { usePermission, useStoreSelectQueries } from "../../../shared";
import { addDays } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";

export const useProductionChart = (props) => {
  const isEditable = usePermission("production-chart-modify");
  const isFututeChartVisble = usePermission("future-chart-view");
  const { list = false, detId = null, futureList = false } = props;

  const { pathname, state } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const navigate = useNavigate();
  const [productionchart, setProductionchart] = useImmer({
    groupedDatas: [],
    templateResultRows: [],
    category_index: 0,
    result: [],
    data: {
      id: 0,
      name: "",
      symbol: "",
      ignore: false,
      no_of_decimals: 0,
      include_decimal_places: false,
      is_default: null,
    },
    filter: {
      search: "",
      category: null,
    },
    isRecipe: 0,
    storeFilter: [],
    recipeList: [],
    updateValue: [],
    allData: [],
    datas: [],
    isChanged: false,
    store: "",
    templateDate: "",
    filters: {
      search: "",
      stores: [],
      date: futureList
        ? [
            {
              startDate: addDays(new Date(), 7),
              endDate: addDays(new Date(), 7),
              key: "selection",
            },
          ]
        : [],
    },
    tempdate: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    futureTempdate: [
      {
        startDate: addDays(new Date(), 7),
        endDate: addDays(new Date(), 7),
        key: "selection",
      },
    ],
    search: "",
    isOpen: false,
    isDatePickerOpen: false,
    isDatePickerOpenfuture: false,
    isTemplateOpen: false,
    id: 0,
    del_id: 0,
    details: {},
    selectedStore: [],
    isLoading: true,
    isList: false,
    alltemplateList: [],
    tempateList: [],
    dataList: [],
    temp_id: "",
    temp_index: 0,
    filterData: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState([]);
  const [debouncedText] = useDebounce(productionchart.filters.search, 1000);

  const {
    listQuery,
    futureListQuery,
    productionChartDetails,
    templateList,
    categorylistQuery,
    recipelistQuery,
    editTemplate,
  } = useProductionChartQuery({
    list,
    futureList,
    detId,
    pagination,
    debouncedText,
    sorting,
    storeId: productionchart.filters.stores,
    dates: productionchart.filters.date,
    isRecipe: productionchart.isRecipe,
  });

  const { listQuery: storeList } = useStoreSelectQueries({
    storeList: (list || futureList) && !isStoreAdmin,
  });
  useEffect(() => {
    if (state) {
      setProductionchart((draft) => {
        draft.filters.date = state.date;
      });
    }
  }, [state]);

  useEffect(() => {
    if (!productionChartDetails?.isFetching) {
      setProductionchart((draft) => {
        draft.selectedStore =
          productionChartDetails?.data?.stores &&
          productionChartDetails?.data?.stores[0];
        draft.templateDate = productionChartDetails?.data?.date;
        draft.isLoading = false;
      });
    }
  }, [productionChartDetails.isFetching]);

  useEffect(() => {
    if (templateList.data) {
      setProductionchart((draft) => {
        draft.alltemplateList = templateList.data;
        draft.tempateList = templateList.data;
      });
    }
  }, [templateList.isFetching]);

  const handleModal = (data) => {
    setProductionchart((draft) => {
      draft.isOpen = data;
    });
  };
  const handleTemplateModal = (data) => {
    setProductionchart((draft) => {
      draft.isTemplateOpen = data;
    });
  };

  const handleSelectStore = (data) => {
    setProductionchart((draft) => {
      draft.selectedStore = data;
      draft.temp_id = data?.templates[0]?.id;
    });
  };
  useEffect(() => {
    if (productionchart?.templateDate && productionChartDetails?.data?.stores) {
      handleSelectStore(productionChartDetails?.data?.stores[0]);
    }
  }, [productionchart.templateDate, productionChartDetails?.data?.stores]);
  const searchHandler = (v) => {
    setProductionchart((draft) => {
      draft.filters.search = v;
    });
  };

  const handleStoreSelect = (e, v) => {
    setProductionchart((draft) => {
      draft.filters.stores = v;
    });
  };

  const onChangeDate = (e) => {
    setProductionchart((draft) => {
      draft.tempdate = e;
    });
  };

  const onChangeDateFuture = (e) => {
    setProductionchart((draft) => {
      draft.futureTempdate = e;
    });
  };

  const handleDateChangeApply = (e) => {
    setProductionchart((draft) => {
      draft.filters.date = draft.tempdate;
      draft.isDatePickerOpen = false;
    });
  };

  const handleViewCharts = (e) => {
    setProductionchart((draft) => {
      draft.filters.date = draft.futureTempdate;
      draft.isDatePickerOpenfuture = false;
    });

    navigate(
      isStoreAdmin
        ? "/store-admin/production-charts/futureChart"
        : "/production-charts/futureChart",
      {
        state: { date: productionchart.futureTempdate },
      }
    );
  };

  const handleIsOpen = (e) => {
    setProductionchart((draft) => {
      draft.isDatePickerOpen = e;
    });
  };
  const handleIsOpenFuture = (e) => {
    setProductionchart((draft) => {
      draft.isDatePickerOpenfuture = e;
    });
  };

  const onSearchChange = (string) => {
    setProductionchart((draft) => {
      draft.tempateList = draft.alltemplateList.filter((o) =>
        Object.keys(o).some((k) =>
          o["template_name"].toLowerCase().includes(string.toLowerCase())
        )
      );
    });
  };
  useEffect(() => {
    if (!productionchart.isTemplateOpen) {
      setProductionchart((draft) => {
        draft.tempateList = draft.alltemplateList;
      });
    }
  }, [productionchart.isTemplateOpen]);

  const searchFn = (value, data) => {
    if (!value || !data) return data;

    const searchValue = value.toLowerCase();

    return data
      ?.map((category) => {
        const filteredSubRows = category?.subRows?.filter((subRow) => {
          return subRow.name?.toLowerCase().includes(searchValue);
        });

        return filteredSubRows.length > 0
          ? { ...category, subRows: filteredSubRows }
          : null;
      })
      .filter(Boolean)
      .sort(
        (a, b) => a.subcategory_display_order - b.subcategory_display_order
      );
  };

  const handleCustomTableChange = () => {
    let groupedData = productionchart?.dataList[
      productionchart?.temp_index
    ]?.recipeList?.reduce((result, product) => {
      if (!result[product.category_id]) {
        result[product.category_id] = {
          category_id: product.category_id,
          category_name: product.category_name,
          template_id: productionchart?.temp_id,
          subRows: [],
        };
      }
      result[product.category_id].subRows.push(product);
      return result;
    }, {});
    setProductionchart((draft) => {
      draft.groupedDatas = groupedData;
    });
  };
  useEffect(() => {
    if (recipelistQuery.data) {
      setProductionchart((draft) => {
        draft.recipeList = recipelistQuery.data.map((obj) => {
          return {
            ...obj,
            wastage: 0,
            quantity: 0,
            isChanged: false,
          };
        });
      });
    }
  }, [recipelistQuery.data]);

  const handleRecipes = async () => {
    try {
      const recipes = [];
      const filteredRecipes = productionchart.recipeList?.filter(
        (i) => i.category_id === productionchart?.chipCategoryId
      );
      filteredRecipes?.forEach((item) => {
        if (!recipes.find((ri) => ri.id === item.subcategory_id)) {
          recipes.push({
            id: item.subcategory_id,
            recipe_name: item.subcategory_name,
            subRows: [],
          });
        }
      });
      if (recipes) {
        filteredRecipes?.forEach((item) => {
          var findRecipe = recipes.find((ri) => ri.id === item.subcategory_id);
          if (findRecipe) {
            findRecipe.subRows.push({
              id: item.id,
              recipe_name: item.name,
              status: item.status,
              store: item.stores,
              type: item.type,
            });
          }
        });
      }
      return recipes;
    } catch (err) {
      return [];
    }
  };
  const onChangeFilter = (e, data, i) => {
    setProductionchart((draft) => {
      draft.category_index = i;
      draft.filter.category = e.id;
      draft.filter.selectedCategory = e;
      draft.datas = draft.allData.filter((v) => e.id === v.category_id);
    });
  };
  const onTemplateSearchChange = (value) => {
    setProductionchart((draft) => {
      draft.filter.search = value;
      draft.templateResultRows = searchFn(value, productionchart?.result);
    });
  };
  const updateTableData = (rowIndex, columnId, value, row, key) => {
    function updateDatalist(arr, key) {
      return arr?.map((v) => {
        if (v.category_id === row.category_id) {
          return {
            ...v,
            subRows: v.subRows.map((val) => {
              if (val.id === row.id) {
                return { ...val, [key]: value };
              } else return val;
            }),
          };
        } else return v;
      });
    }
    function updateOrAddObject(row, value, key) {
      const { id, wastage, quantity } = row;
      let arr = productionchart.updateValue;
      let isHave = arr?.some((v) => v.recipe_id === id);
      if (isHave) {
        arr = productionchart.updateValue.map((data) => {
          if (data.recipe_id === id) {
            return {
              ...data,
              [key]: Number(value),
              isChanged: true,
            };
          } else
            return {
              ...data,
              isChanged: true,
            };
        });
      } else
        arr = [
          ...arr,
          {
            recipe_id: id,
            wastage: wastage,
            quantity: quantity,
            isChanged: true,
            [key]: Number(value),
          },
        ];
      return arr;
    }
    function updateValue(row, value, key) {
      const { id, wastage, quantity } = row;

      const arr = productionchart?.dataList?.map((data, index) => {
        if (index === productionchart.temp_index) {
          return {
            ...data,
            recipeList: data?.recipeList?.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  [key]: Number(value),
                  isChanged: true,
                };
              } else return item;
            }),
          };
        } else return data;
      });
      return arr;
    }
    setProductionchart((draft) => {
      draft.datas = updateDatalist(draft.datas, key);
      draft.allData = updateDatalist(draft.allData, key);
      draft.updateValue = updateOrAddObject(row, value, key);
      draft.dataList = updateValue(row, value, key);
      draft.isChanged = true;
    });
  };
  useEffect(() => {
    if (productionchart?.store || productionchart.isRecipe) {
      setProductionchart((draft) => {
        draft.storeFilter = productionChartDetails?.data?.stores?.filter(
          (item) => item?.store_id === productionchart?.store
        );
      });
    }
  }, [productionchart?.store, productionchart.isRecipe]);
  useEffect(() => {
    if (
      productionchart?.storeFilter?.length &&
      productionchart.recipeList.length
    ) {
      const groupedData =
        productionchart?.storeFilter[0]?.other_recipes?.reduce((acc, item) => {
          const { template_id, template_name } = item;

          if (!acc[template_id]) {
            acc[template_id] = {
              template_id,
              template_name,
              recipes: [],
            };
          }

          acc[template_id].recipes.push(item);
          return acc;
        }, {});
      let x = Object.values(groupedData);
      let y = x.map((data) => {
        let a = data.recipes.sort((a, b) => a.recipe_id - b.recipe_id);
        let b = productionchart.recipeList.map((data) => {
          if (data?.id === a.at(-1)?.recipe_id) {
            const val = {
              ...data,
              ...a.at(-1),
            };
            a.pop();
            return val;
          } else return data;
        });

        const updatedY = b.map((yItem) => {
          const xItem = a.find((xItem) => xItem.recipe_id === yItem.id);
          return xItem
            ? { ...yItem, quantity: xItem.quantity, wastage: xItem.wastage }
            : yItem;
        });
        return { ...data, recipeList: updatedY };
      });

      setProductionchart((draft) => {
        draft.dataList = y;
      });
    }
  }, [productionchart.storeFilter, productionchart.recipeList]);
  const onStoreChange = ({ target, data }) => {
    const storeFilter = data.find((i) => i.store_id === target?.value);
    setProductionchart((draft) => {
      draft.store = target?.value;
      draft.isRecipe = productionchart.isRecipe === 0 ? 1 : 0;
      draft.isList = true;
      draft.temp_id = storeFilter?.templates[0]?.id;
    });
  };

  const convertedData = productionchart?.dataList?.map((template) => {
    const { template_id, template_name } = template;

    const recipes = template?.recipeList?.map((recipe) => ({
      recipe_id: recipe.id,
      quantity: recipe.quantity,
      wastage: recipe.wastage,
      isChanged: recipe.wastage > 0 || recipe.quantity > 0 ? true : false,
    }));

    return {
      template_id,
      recipes: recipes?.filter((i) => i.isChanged === true),
    };
  });

  const filterRecipeList = convertedData?.map((i) => {
    return i?.recipes?.filter((p) => p?.isChanged === true);
  });

  const onUpdate = () => {
    const payload = {
      date: productionchart?.templateDate,
      store_id: productionchart?.store,
      templates: convertedData,
    };
    editTemplate.mutate({ payload, handleModal });
  };
  const handleTemplateChange = (e, id) => {
    setProductionchart((draft) => {
      draft.temp_index = e;
      draft.temp_id = id;
    });
  };

  useEffect(() => {
    if (productionchart?.isOpen) {
      setProductionchart((draft) => {
        draft.store = productionchart?.selectedStore?.store_id;
        draft.isRecipe = productionchart.isRecipe === 0 ? 1 : 0;
      });
    }
  }, [productionchart?.isOpen]);

  const onClearFilter = () => {
    setProductionchart((draft) => {
      draft.filters = {
        search: "",
        stores: [],
        date: [],
      };
      draft.tempdate = [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ];
      draft.templateDate = "";
    });
  };
  const disableclearButton =
    productionchart.filters.date.length > 0 ||
    productionchart.filters.search ||
    productionchart.filters.stores.length > 0;

  const handleCustomFieldChange = (id, e, fieldType, data) => {
    const { value } = e.target;
    setProductionchart((draft) => {
      draft.dataList = productionchart?.dataList?.map((data, index) => {
        if (index === productionchart.temp_index) {
          return {
            ...data,
            recipeList: data?.recipeList?.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  [fieldType]: Number(value),
                  isChanged: true,
                };
              } else return item;
            }),
          };
        } else return data;
      });
    });
  };

  useEffect(() => {
    if (productionchart.isOpen && categorylistQuery?.data?.length) {
      onChangeFilter(categorylistQuery?.data[0]);
      setProductionchart((draft) => {
        draft.isInitialLoad = true;
      });
    }
  }, [productionchart.isOpen, categorylistQuery.data]);

  useEffect(() => {
    if (
      productionchart?.dataList &&
      productionchart?.filter?.category &&
      productionchart?.temp_id
    ) {
      const findData = productionchart?.dataList.find(
        (i) => i.template_id === productionchart?.temp_id
      )?.recipeList;

      let groupedData = findData?.reduce((acc, item) => {
        let key = item["subcategory_id"];
        if (!acc[key]) {
          acc[key] = {
            category_id: item["category_id"],
            subcategory_name: item["subcategory_name"],
            subcategory_display_order: item["subcategory_display_order"],
            subRows: [],
          };
        }
        acc[key].subRows.push(item);
        return acc;
      }, {});
      if (groupedData) {
        let result =
          !productionchart?.filter?.category && groupedData
            ? Object.values(groupedData)
            : Object.values(groupedData).filter(
                (v) => productionchart?.filter?.category === v?.category_id
              );
        setProductionchart((draft) => {
          draft.result = result;
          draft.templateResultRows = searchFn(
            productionchart.filter.search,
            result
          );
        });
      }
    }
  }, [
    productionchart.dataList,
    productionchart.temp_id,
    productionchart.filter.category,
  ]);

  return {
    productionchart,
    pagination,
    handleModal,
    handleTemplateModal,
    dataList,
    detailsData,
    handleSelectStore,
    setPagination,
    listQuery,
    futureListQuery,
    productionChartDetails,
    templateList,
    sorting,
    setSorting,
    searchHandler,
    storeList,
    handleStoreSelect,
    onChangeDate,
    onChangeDateFuture,
    handleDateChangeApply,
    handleIsOpen,
    handleIsOpenFuture,
    handleViewCharts,
    onSearchChange,
    categorylistQuery,
    handleCustomTableChange,
    handleRecipes,
    recipelistQuery,
    onChangeFilter,
    onTemplateSearchChange,
    updateTableData,
    onStoreChange,
    onUpdate,
    editTemplate,
    filterRecipeList,
    handleTemplateChange,
    convertedData,
    onClearFilter,
    disableclearButton,
    isFututeChartVisble,
    isEditable,
    handleCustomFieldChange,
  };
};
