import { useImmer } from "use-immer";
import { useContext, useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import {
  useCategorySelectChip,
  usePermission,
  useStatusSelect,
  useStoreSelect,
  useStoreSelectQueries,
} from "../../../shared";
import { useTemplateQueries } from "./useTemplateQueries";
import { useSceduleSelect } from "../../../shared/hooks/useSceduleSelect";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useTempDelete } from "../../../shared/hooks/useTempDelete";
import { navigateBasedOnUserType } from "../../../utils";
import { AppContext } from "../../../store";

export const useTemplates = ({
  list = false,
  status,
  schedule = 0,
  details_id,
  dropdownlist = false,
  updateId,
  isList,
}) => {
  const isDeletable = usePermission("template-delete");
  const isCreateVisible = usePermission("template-create");
  const isEditable = usePermission("template-modify");
  const isStatusChange = usePermission("template-status_change");
  const isStoreAssign = usePermission("template-assign_store");
  const isCreateSchedule = usePermission("template-shedule");
  const isCopyTemplate = usePermission("template-copy");
  const [, forceUpdate] = useState();

  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const { globalData } = useContext(AppContext);
  const navigate = useNavigate();
  const { deleteInfo, onDelete, resetDeleteInfo, onDeleteDetails } =
    useTempDelete();
  const [templates, setTemplates] = useImmer({
    templateResultRows: [],
    isInitialLoad: false,
    selectedCategory: {},
    groupedDatas: [],
    isRecurringDateDelete: 0,
    isRecurringDialog: false,
    isDeleteDialogOpen: false,
    combinedList: [],
    isDateSaveButton: true,
    chipCategoryId: "",
    selectedDate: "",
    isList: false,
    isChanged: false,
    isTrue: false,
    isRegenerateOpen: false,
    isOpenDialog: false,
    templateItems: {},
    templateBatchItems: {},
    batchesItems: {},
    recipeItems: {},
    templateDetails: {},
    recipeList: [],
    allData: [],
    datas: [],
    updateValue: [],
    indexColumn: "",
    filter: {
      search: "",
      category: null,
    },
    tempId: "",
    data: {
      date_range_week: {
        date: [1],
      },

      month_onday: {
        day: "",
      },

      month_onthe: {
        weekofmonth: "",
        dayofweek: "",
      },

      year_on: {
        month: "",
        day: "",
      },

      year_onthe: {
        dayofweek: "",
        date: "",
        month: "",
      },
      template_name: "",
      custom_date: 0,
      template_id: "",
      start_date: "",
      end_date: "",
      period: "",
      is_end: "",

      drop_down_list: 2,
      custom_dates: [],
      custom_dates_view: [],
      date_range_radio: 6,
      date_range_month_radio: 4,
      status: "",
      shedule_types: "",
      update_date: "",
    },
    isLoading: false,
    search: "",

    id: "",
    sheduled_types: "",

    selectedStatus: -1,
    selectedSchedule: -1,

    effective_date: "",
    alert_radio: "",

    scheduleOpen: false,
    isScheduleUpdateOpen: false,

    isStoreOpen: false,

    isOpen: false,

    isStatusOpen: false,
    isNameOpen: false,

    isDeleteOpen: false,

    filters: {
      storefilter: [],
      all: [],
    },
    filterApplied: {},
    result: [],
  });
  const moduleName = "templates";
  const {
    setTableState,
    tableData,
    clearAllFilters,
    setSorting,
    setPagination,
    setSearchState,
  } = useContext(AppContext);
  const { sorting, pagination, search } = tableData[moduleName];
  const [debouncedText] = useDebounce(search, 1000);
  const {
    listQuery,
    deleteTemplate,
    templateDetails,
    templateProductionDaysDetails,
    createScedule,
    dropdownListQuery,
    updateTemplateStatus,
    updateSceduler,
    categorylistQuery,
    recipelistQuery,
    generateBatch,
    state,
    reGenerateBatch,
    copyBatch,
    updateTemplateName,
    setState,
    createTemplateName,
    handleAddCustomDays,
    deleteRecurringDate,
  } = useTemplateQueries({
    list,
    debouncedText,
    pagination: pagination,
    status: tableData?.templates?.data?.selectedStatus,
    schedule: tableData?.templates?.data?.selectedSchedule,
    sorting,
    resetDeleteInfo,
    details_id,
    tempId: templates.tempId,
    updateId,
    dropdownlist,
    filterApplied: tableData?.templates?.data?.storefilter,
    setTemplates,
    templates,
    isStoreAdmin,
    selectedDate: templates?.selectedDate,
    isList,
  });

  const { listQuery: storeLists } = useStoreSelectQueries({
    storeList: true && !isStoreAdmin,
  });
  const handleBack = () => {
    setState((draft) => {
      draft.isCreate = false;
    });
  };
  const StoreValuesHandler = (event, option) => {
    const item = option?.map((item) => {
      return { ...item };
    });
    setTableState({
      tableName: moduleName,
      key: "storefilter",
      value: item,
    });
    setTemplates((draft) => {
      draft.filters.status = "";
    });
  };
  const onChangeCategoryHandler = (value) => {
    setTemplates((draft) => {
      draft.chipCategoryId = draft.chipCategoryId === value ? 0 : value;
    });
  };
  const selectChipCategory = useCategorySelectChip({
    onChangeCategoryHandler,
  });
  const onChangeStateHandler = (value) => {
    setTemplates((draft) => {
      draft.selectedStore = value;
    });
  };

  const selectStore = useStoreSelect({ storelist: true, onChangeStateHandler });
  const onChangeStatusHandler = (value) => {
    setTableState({
      tableName: moduleName,
      key: "selectedStatus",
      value: value,
    });
  };
  const selectStatus = useStatusSelect({ onChangeStatusHandler });

  const onChangeSceduleHandler = (value) => {
    setTableState({
      tableName: moduleName,
      key: "selectedSchedule",
      value: value,
    });
  };
  const selectSchedule = useSceduleSelect({ onChangeSceduleHandler });

  const onSearchHandler = (search) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: search,
    });
  };

  const clearSearch = () => {
    setTemplates((draft) => {
      draft.search = "";
    });
  };

  const onClearFilter = () => {
    clearAllFilters({ tableName: moduleName });
  };
  const disableclearButton =
    (tableData.templates.data.selectedStatus === null ||
      tableData.templates.data.selectedStatus === "") &&
    (tableData.templates.data.selectedSchedule === null ||
      tableData.templates.data.selectedSchedule === "") &&
    Array.isArray(tableData.templates.data.storefilter) &&
    tableData.templates.data.storefilter.length === 0
      ? true
      : false;

  const handleDeleteModal = (type, data) => {
    setTemplates((draft) => {
      draft.isOpen = type;
      draft.id = data;
    });
  };

  // status change
  const handleClickOpen = (data, id) => {
    setTemplates((draft) => {
      draft.isStatusOpen = data;
      if (data === true) {
        draft.id = id;
      } else {
        draft.id = null;
        draft.alert_radio = "";
        draft.effective_date = "";
      }
    });
    validator.current.hideMessages();
  };

  //schedule update
  const handleClickUpdateOpen = (data, id) => {
    setTemplates((draft) => {
      draft.isScheduleUpdateOpen = data;
      if (data === true) {
        draft.id = id;
      } else {
        draft.alert_radio = "1";
      }
    });
    validator.current.hideMessages();
  };

  // store update
  const handleAlertClickOpen = (data, id) => {
    setTemplates((draft) => {
      draft.isStoreOpen = data;
      if (data === true) {
        draft.id = id;
      } else {
        draft.id = null;
        draft.alert_radio = "";
      }
    });
    validator.current.hideMessages();
  };

  //scedule save and update
  const handleScheduleOpen = (data, id) => {
    setTemplates((draft) => {
      draft.scheduleOpen = data;
      if (data === true) {
        draft.id = id;
      }
    });
    validator.current.hideMessages();
  };

  const handleRemoveRecurringDate = (type) => {
    setTemplates((draft) => {
      draft.isRecurringDialog = type;
    });
  };

  //template name
  const handleNameOpen = (data, id) => {
    setTemplates((draft) => {
      draft.isNameOpen = data;
      if (data === true) {
        draft.id = id;
      } else {
        draft.id = null;
      }
    });
    validator.current.hideMessages();
  };

  const confirmDelete = () => {
    if (deleteInfo?.id) {
      deleteTemplate.mutate(deleteInfo.id, isList);
    } else {
      deleteTemplate.mutate(templates.id, isList);
      handleDeleteModal(false);
      navigateBasedOnUserType("/templates", isStoreAdmin, navigate);
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setTemplates((draft) => {
      draft.data[name] = value;
    });
  };

  useEffect(() => {
    if (templateDetails?.data || templates.isList) {
      setTemplates((draft) => {
        draft.data.status = templateDetails?.data?.status;
        draft.data.template_name = templateDetails?.data?.template_name;
      });
      const array1 =
        templateDetails?.data?.other_recipes?.map((item) => ({
          id: item.recipe_id,
          quantity: item.quantity,
          wastage: item.wastage,
          isChanged: item?.wastage > 0 || item?.quantity > 0 ? true : false,
        })) || [];
      const array2 =
        templateDetails?.data?.batches?.map((item) => ({
          id: item.recipe_id,
          quantity: item.quantity,
          wastage: item.wastage,
          isChanged: item?.wastage > 0 || item?.quantity > 0 ? true : false,
        })) || [];
      let combinedRecipes = [...array1, ...array2];
      const map = new Map(
        templates?.recipeList?.map((item) => [item.id, item])
      );

      // Update the map with array2 data
      combinedRecipes.forEach((item) => {
        if (map.has(item.id)) {
          map.set(item.id, { ...map.get(item.id), ...item });
        }
      });

      const combinedArray = Array.from(map.values());
      setTemplates((draft) => {
        draft.recipeList = combinedArray;
        draft.combinedList = combinedArray;
      });
    }
  }, [templateDetails?.data, templates.isList]);
  useEffect(() => {
    if (templateProductionDaysDetails?.data) {
      const {
        custom_date,
        start_date,
        end_date,
        period,
        drop_down_list,
        added_dates,
        is_end,
        sheduled_types,
        date,
        day,
        month,
        weekofmonth,
        dayofweek,
      } = templateProductionDaysDetails?.data[0];
      setTemplates((draft) => {
        draft.isLoading = false;
        draft.data = {
          ...templateProductionDaysDetails?.data,
          custom_date: custom_date,
          start_date: start_date,
          end_date: end_date,
          period: period,
          drop_down_list: drop_down_list,
          custom_dates: added_dates,
          custom_dates_view: added_dates,
          is_end: is_end,
          shedule_types: sheduled_types,
          date_range_month_radio: sheduled_types,
          date_range_radio: sheduled_types,
        };
        draft.data.date_range_week = {
          date: date,
        };
        draft.data.month_onday = {
          day: day,
        };
        draft.data.month_onthe = {
          weekofmonth: weekofmonth,
          dayofweek: dayofweek,
        };
        draft.data.year_on = {
          month: month,
          day: day,
        };
        draft.data.year_onthe = {
          dayofweek: dayofweek,
          date: parseInt(date),
          month: month,
        };
      });
    }
  }, [templateProductionDaysDetails?.data, details_id]);
  const onDeleteTagSelect = (data, type) => {
    setTemplates((draft) => {
      draft.selectedDate = data;
      draft.isDeleteDialogOpen = type;
      draft.data.custom_dates = templates.data.custom_dates.filter((value) => {
        return value !== data;
      });
    });
  };
  const onChangeMultipleDate = (selectedValues) => {
    setTemplates((draft) => {
      draft.isDateSaveButton = selectedValues.length ? false : true;
    });
    const formatted = selectedValues?.map((date, index) => date?.format());
    setTemplates((draft) => {
      draft.data.custom_dates = formatted;
      draft.data.custom_dates_view = formatted;
    });
  };

  const onChangeDate = (name, selectedDate) => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setTemplates((draft) => {
      draft.data[name] = formattedDate;
    });
  };

  const onChangeEffectiveDate = (selectedDate) => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    setTemplates((draft) => {
      draft.effective_date = formattedDate
        ? formattedDate
        : moment().format("YYYY-MM-DD");
    });
  };

  const RadioButtonChange = (value, name) => {
    const customDateValue = parseInt(value, 10);
    setTemplates((draft) => {
      draft.data[name] = customDateValue;
    });
  };

  const onChangeSceduler = (e) => {
    const { value } = e.target;
    setTemplates((draft) => {
      draft.data.month_onday.day = value;
    });
  };
  const onChangeYear = (e) => {
    const { value } = e.target;
    setTemplates((draft) => {
      draft.data.year_on.day = value;
    });
  };
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setTemplates((draft) => {
      draft.data[name] = value;
    });
  };

  ///////////////////////////////////days////////////
  const handleRepeatOptionChange = (event, option) => {
    const stateOption = option ? option.value : "";
    setTemplates((draft) => {
      draft.data.drop_down_list = stateOption;
    });
  };
  ////////////month on the ////////////////////
  const weekOfMonthOptionChange = (event, option) => {
    const WeekOfMonthOption = option ? option.value : "";
    setTemplates((draft) => {
      draft.data.month_onthe.weekofmonth = WeekOfMonthOption;
    });
  };
  const weekDaysOptionChange = (event, option) => {
    const weekOption = option ? option.value : "";
    setTemplates((draft) => {
      draft.data.month_onthe.dayofweek = weekOption;
    });
  };
  ////////////////year on ///////////////////
  const monthOptionChange = (event, option) => {
    const monthOption = option ? option.value : "";
    setTemplates((draft) => {
      draft.data.year_on.month = monthOption;
    });
  };
  ////////////////year on the///////////////////
  const DaysOfWeekOptionChange = (event, option) => {
    const daysOfWeekOption = option ? option.value : "";
    setTemplates((draft) => {
      draft.data.year_onthe.dayofweek = daysOfWeekOption;
    });
  };
  const WeekDaysOptionChange = (event, option) => {
    const weekDaysOption = option ? option.value : "";
    setTemplates((draft) => {
      draft.data.year_onthe.date = weekDaysOption;
    });
  };

  const OptionChange = (event, option) => {
    const optionValue = option ? option.value : "";
    setTemplates((draft) => {
      draft.data.year_onthe.month = optionValue;
    });
  };

  const detailsStatusChangeHandler = (event, option) => {
    const statusOpt = option ? option.id : "";
    setTemplates((draft) => {
      draft.data.status = statusOpt;
    });
  };

  const handleDayClick = (dayValue) => {
    setTemplates((draft) => {
      if (!Array.isArray(draft.data.date_range_week.date)) {
        draft.data.date_range_week.date = [];
      }
      const index = draft.data.date_range_week.date.indexOf(dayValue);
      if (index === -1) {
        draft.data.date_range_week.date.push(dayValue);
      } else {
        draft.data.date_range_week.date.splice(index, 1);
      }
    });
  };

  const statusChangeHandler = (event, option) => {
    detailsStatusChangeHandler(event, option);
    // handleClickOpen(true, templates.id);
  };

  const onSubmitStatus = (event, option) => {
    setTemplates((draft) => {
      draft.isLoading = true;
    });
    if (validator.current.allValid()) {
      const payload = {
        status: option.id,
      };
      updateTemplateStatus.mutate({
        details_id,
        payload,
        handleClickOpen,
        setTemplates,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const RemoveButtonHandeler = () => {
    setTemplates((draft) => {
      draft.data.end_date = null;
    });
  };

  useEffect(() => {
    if (templateProductionDaysDetails?.data) {
      setTemplates((draft) => {
        draft.isLoading = false;
        draft.data.status = templateDetails?.data?.status;
      });

      if (templates?.tempId) {
        const array1 = templateDetails?.data?.other_recipes?.map((item) => ({
          id: item.recipe_id,
          quantity: item.quantity,
          wastage: item.wastage,
          isChanged: item.wastage > 0 || item.quantity > 0 ? true : false,
        }));
        const array2 = templateDetails?.data?.batches?.map((item) => ({
          id: item.recipe_id,
          quantity: item.quantity,
          wastage: item.wastage,
          isChanged: item.wastage > 0 || item.quantity > 0 ? true : false,
        }));
        if (array1 && array2) {
          let combinedRecipes = [...array1, ...array2];
          setTemplates((draft) => {
            draft.recipeList = [...draft.recipeList, ...combinedRecipes];
            draft.data.template_name = templateDetails?.data?.template_name;
          });
          combinedRecipes.sort((a, b) => a.id - b.id);
          let b = templates.recipeList.map((data) => {
            if (data?.id === combinedRecipes?.at(-1)?.id) {
              const val = { ...data, ...combinedRecipes.at(-1) };
              combinedRecipes.pop();
              return val;
            } else return data;
          });
          setTemplates((draft) => {
            draft.recipeList = b;
          });
        }
      }
    }
  }, [
    templateDetails?.data,
    templates.tempId,
    templates.filter.search,
    templates.isOpen,
  ]);
  const onSubmit = () => {
    setTemplates((draft) => {
      draft.isLoading = true;
    });
    if (validator.current.allValid()) {
      const {
        custom_date,
        start_date,
        end_date,
        period,
        is_end,
        date_range_week,
        month_onday,
        month_onthe,
        year_on,
        year_onthe,
        date_range_radio,
        date_range_month_radio,
        drop_down_list,
      } = templates?.data;

      const { id, effective_date } = templates;
      if (drop_down_list === 1) {
        const isEnd = end_date ? 1 : 0;
        const payload = {
          custom_date,
          template_id: templateDetails?.data?.template_id,
          start_date,
          ...(end_date && { end_date }),
          is_end: isEnd,
          period: Number(period),
          effective_date,
          shedule_types: 2,
        };

        updateSceduler.mutate({
          data: payload,
          handleClickUpdateOpen,
          handleScheduleOpen,
          setTemplates,
        });
      } else if (drop_down_list === 2) {
        const isEnd = end_date ? 1 : 0;
        const payload = {
          custom_date,
          template_id: templateDetails?.data?.template_id,
          start_date,
          ...(end_date && { end_date }),
          is_end: isEnd,
          period: Number(period),
          date: date_range_week.date,
          effective_date,
          shedule_types: 3,
        };
        updateSceduler.mutate({
          data: payload,
          handleClickUpdateOpen,
          handleScheduleOpen,
          setTemplates,
        });
      } else if (drop_down_list === 3 && date_range_month_radio === 4) {
        const isEnd = end_date ? 1 : 0;
        const payload = {
          custom_date,
          template_id: templateDetails?.data?.template_id,
          start_date,
          ...(end_date && { end_date }),
          is_end: isEnd,
          period: Number(period),
          day: month_onday.day,
          effective_date,
          shedule_types: 4,
        };

        updateSceduler.mutate({
          data: payload,
          handleClickUpdateOpen,
          handleScheduleOpen,
          setTemplates,
        });
      } else if (drop_down_list === 3 && date_range_month_radio === 5) {
        const isEnd = end_date ? 1 : 0;
        const payload = {
          custom_date,
          template_id: templateDetails?.data?.template_id,
          start_date,
          ...(end_date && { end_date }),
          is_end: isEnd,
          period: Number(period),
          weekofmonth: month_onthe.weekofmonth,
          dayofweek: month_onthe.dayofweek,
          effective_date,
          shedule_types: 5,
        };

        updateSceduler.mutate({
          data: payload,
          handleClickUpdateOpen,
          handleScheduleOpen,
          setTemplates,
        });
      } else if (drop_down_list === 4 && date_range_radio === 6) {
        const isEnd = end_date ? 1 : 0;
        const payload = {
          custom_date,
          template_id: templateDetails?.data?.template_id,
          start_date,
          ...(end_date && { end_date }),
          is_end: isEnd,
          period: Number(period),
          month: year_on.month,
          day: year_on.day,
          effective_date,
          shedule_types: 6,
        };

        updateSceduler.mutate({
          data: payload,
          handleClickUpdateOpen,
          handleScheduleOpen,
          setTemplates,
        });
      } else if (drop_down_list === 4 && date_range_radio === 7) {
        const isEnd = end_date ? 1 : 0;
        const payload = {
          custom_date,
          template_id: templateDetails?.data?.template_id,
          start_date,
          ...(end_date && { end_date }),
          is_end: isEnd,
          period: Number(period),
          dayofweek: year_onthe?.dayofweek,
          date: year_onthe?.date,
          month: year_onthe?.month,
          effective_date,
          shedule_types: 7,
        };
        updateSceduler.mutate({
          data: payload,
          handleClickUpdateOpen,
          handleScheduleOpen,
          setTemplates,
        });
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const onNameCreateSubmit = () => {
    setTemplates((draft) => {
      draft.isLoading = true;
    });

    const { template_name } = templates.data;
    const payload = {
      template_name,
      template_id: state?.templateDetails?.data?.template_id,
    };
    createTemplateName.mutate({
      payload,
      handleOpenClick,
      handleOpenClickDetailCopy,
    });
  };
  const onNameSubmit = () => {
    setTemplates((draft) => {
      draft.isLoading = true;
    });
    if (validator.current.allValid()) {
      const { template_name } = templates.data;
      const payload = {
        template_name,
      };
      updateTemplateName.mutate({
        details_id,
        payload,
        handleNameOpen,
        setTemplates,
      });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
      setTemplates((draft) => {
        draft.isLoading = false;
      });
    }
  };

  useEffect(() => {
    if (recipelistQuery.data) {
      const filterRecipeList = recipelistQuery.data.filter(
        (i) => i.condition !== 0
      );
      setTemplates((draft) => {
        draft.isList = true;
        draft.recipeList = filterRecipeList.map((obj) => {
          return {
            ...obj,
            wastage: obj?.wastage,
            quantity: 0,
            isChanged: false,
          };
        });
      });
    }
  }, [recipelistQuery.data]);

  const handleRecipes = async () => {
    try {
      const recipes = [];

      const filteredRecipes = templates.recipeList?.filter(
        (i) => i.category_id === templates?.chipCategoryId
      );
      filteredRecipes?.forEach((item) => {
        if (!recipes.find((ri) => ri.id === item.subcategory_id)) {
          recipes.push({
            id: item.subcategory_id,
            recipe_name: item.subcategory_name,
            subRows: [],
          });
        }
      });
      if (recipes) {
        filteredRecipes?.forEach((item) => {
          var findRecipe = recipes.find((ri) => ri.id === item.subcategory_id);
          if (findRecipe) {
            findRecipe.subRows.push({
              id: item.id,
              recipe_name: item.name,
              status: item.status,
              store: item.stores,
              type: item.type,
            });
          }
        });
      }
      return recipes;
    } catch (err) {
      return [];
    }
  };
  useEffect(() => {
    if (state.isCreate) {
      handleOptionChangeRecipeBatch(
        state?.templateDetails?.data?.batches[0],
        0
      );
    }
  }, [state.isCreate, state?.templateDetails?.data]);

  useEffect(() => {
    handleCustomTableChange();
  }, [templates?.recipeList]);
  const handleCustomTableChange = (data) => {
    if (templates?.recipeList) {
      const groupByCategory = templates.recipeList.reduce((result, product) => {
        if (!result[product.category_id]) {
          result[product.category_id] = {
            category_id: product.category_id,
            category_name: product.category_name,
            subRows: [],
          };
        }
        result[product.category_id].subRows.push(product);
        setTemplates((draft) => {
          draft.groupedDatas = result;
        });
        return result;
      }, {});
    }
  };

  const onChangeFilter = (e, data) => {
    if (e?.id && data?.[e?.id]?.subRows?.length) {
      const groupedBySubcategory = Object.values(
        data[e?.id]?.subRows?.reduce((acc, item) => {
          const key = item["subcategory_id"];
          if (!acc[key]) {
            acc[key] = {
              category_id: item["category_id"],
              subcategory_name: item["subcategory_name"],
              subcategory_display_order: item["subcategory_display_order"],
              subRows: [],
            };
          }
          acc[key].subRows.push({
            ...item,
            quantity: item.quantity ?? 0,
            wastage: item.wastage ?? 0,
          });
          return acc;
        }, {})
      );

      setTemplates((draft) => {
        draft.filter.search = "";
        draft.filter.category = e.id;
        draft.filter.selectedCategory = e;
        draft.result = groupedBySubcategory;
        draft.templateResultRows = groupedBySubcategory;
      });
    } else {
      setTemplates((draft) => {
        draft.filter.search = "";
        draft.filter.category = e.id;
        draft.filter.selectedCategory = e;
        draft.result = [];
        draft.templateResultRows = [];
      });
    }
  };

  const afterOnSave = () => {
    setTemplates((draft) => {
      draft.isChanged = false;
    });
  };
  const filterRecipeList = templates.recipeList.filter((i) => i.quantity > 0);
  const filterRecipeListValue = filterRecipeList?.map((item) => ({
    recipe_id: item.id,
    quantity: item.quantity,
    wastage: item.wastage,
  }));
  const batchFilter1 = filterRecipeListValue?.filter((i) => i.quantity !== 0);
  const onUpdate = () => {
    const payload = {
      effective_date: templates.effective_date,
      batches: [...batchFilter1],
    };
    reGenerateBatch.mutate({ data: payload, handleRegenerateOpenClick });
    setTemplates((draft) => {
      draft.isOpen = false;
    });
  };

  const onSave = (data) => {
    const payload = {
      batches: batchFilter1,
    };
    if (templates.tempId) {
      generateBatch.mutate({ data: payload, afterOnSave });
      setTemplates((draft) => {
        draft.isTrue = true;
      });
    } else {
      generateBatch.mutate({ data: payload, afterOnSave });
    }
  };
  const handleOpenClickDetailCopy = async (type, id) => {
    setTemplates((draft) => {
      draft.isOpenDialog = type;
      draft.tempId = id;
    });
    if (type === false) {
      setState((draft) => {
        draft.isCreate = false;
      });
    }
  };
  const handleOpenClick = async (type, id) => {
    setTemplates((draft) => {
      draft.isOpen = type;
      draft.tempId = id;
    });
    if (type === false) {
      setState((draft) => {
        draft.isCreate = false;
      });
    }
  };

  const handleRegenerateOpenClick = async (type, id) => {
    setTemplates((draft) => {
      draft.isRegenerateOpen = type;
    });
  };
  useEffect(() => {
    if (generateBatch) {
      setTemplates((draft) => {
        draft.templateDetails = generateBatch.variable;
      });
    }
  }, [generateBatch]);

  const handleOptionChangeRecipe = async (item, index) => {
    setTemplates((draft) => {
      draft.templateItems = item;
      draft.indexColumn = index;
    });
  };
  useEffect(() => {
    if (state.isCreate) {
      handleOptionChangeRecipe(
        state?.templateDetails?.data?.other_recipes[0],
        0
      );
    }
  }, [state.isCreate, state?.templateDetails?.data]);
  const handleOptionChangeRecipeBatch = async (item, index) => {
    setTemplates((draft) => {
      draft.templateBatchItems = item;
      draft.indexColumn = index;
    });
  };
  useEffect(() => {
    if (state.isCreate) {
      handleOptionChangeRecipeBatch(
        state?.templateDetails?.data?.batches[0],
        0
      );
    }
  }, [state.isCreate, state?.templateDetails?.data]);
  const optionHandleChangeBatches = async (item) => {
    setTemplates((draft) => {
      draft.batchesItems = item;
    });
  };
  const optionHandleChangeRecipies = async (item) => {
    setTemplates((draft) => {
      draft.recipeItems = item;
    });
  };
  useEffect(() => {
    if (templates.isTrue) {
      setTemplates((draft) => {
        draft.tempId = state?.templateDetails?.data?.template_id;
        draft.data.status = templateDetails?.data?.status;
      });
    }
  }, [templates.isTrue, state.templateDetails]);

  const searchFn = (value, data) => {
    if (!value || !data) return data;

    const searchValue = value.toLowerCase();

    return data
      ?.map((category) => {
        const filteredSubRows = category?.subRows?.filter((subRow) => {
          return subRow.name?.toLowerCase().includes(searchValue);
        });

        return filteredSubRows.length > 0
          ? { ...category, subRows: filteredSubRows }
          : null;
      })
      .filter(Boolean)
      .sort(
        (a, b) => a.subcategory_display_order - b.subcategory_display_order
      );
  };

  const onSearchChange = (value) => {
    setTemplates((draft) => {
      draft.filter.search = value;
      draft.templateResultRows = searchFn(value, templates.result);
    });
  };

  const searchHandler = (value) => {
    onSearchChange(value);
  };

  useEffect(() => {
    if (
      (templates.isOpen &&
        templates.groupedDatas &&
        categorylistQuery?.data &&
        templates?.filter?.category &&
        categorylistQuery.data.length > 0 &&
        templates.isInitialLoad === false) ||
      templates.isOpenDialog ||
      templates.isOpen
    ) {
      const filterData = categorylistQuery.data.find(
        (item) => item.id === templates.filter.category
      );
      onChangeFilter(
        templates?.filter?.category ? filterData : categorylistQuery?.data[0],
        templates?.groupedDatas
      );
      setTemplates((draft) => {
        draft.isInitialLoad = true;
      });
    }
  }, [templates.groupedDatas, templates.isOpen, templates.filter.category]);
  const onSaveCustomDays = () => {
    const payload = {
      custom_dates: templates?.data?.custom_dates,
      template_id: details_id,
    };
    handleAddCustomDays.mutate({ data: payload, onDeleteTagSelect });
  };

  const handleCustomFieldChange = (id, e, type) => {
    const { value } = e.target;
    setTemplates((draft) => {
      draft.result.forEach((category) => {
        const subRowItem = category.subRows.find((item) => item.id === id);
        if (subRowItem) {
          subRowItem[type] = value;
          subRowItem.isChanged = true;
        }
      });
      draft.templateResultRows.forEach((category) => {
        const subRowItem = category.subRows.find((item) => item.id === id);
        if (subRowItem) {
          subRowItem[type] = value;
          subRowItem.isChanged = true;
        }
      });

      draft.recipeList = draft.recipeList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [type]: value,
            isChanged: true,
          };
        }
        return item;
      });
    });
  };

  const handleClose = () => {
    setState((draft) => {
      draft.isErrorMessage = false;
    });
  };
  const confirmDeleteRecurringDates = () => {
    deleteRecurringDate.mutate({ id: details_id, handleRemoveRecurringDate });
  };
  return {
    listQuery,
    templates,
    validator,
    clearSearch,
    pagination,
    setPagination,
    onSearchHandler,
    sorting,
    setSorting,
    selectStore,
    selectStatus,
    onClearFilter,
    disableclearButton,
    selectSchedule,
    handleClickOpen,
    confirmDelete,
    handleDeleteModal,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    templateDetails,
    templateProductionDaysDetails,
    createScedule,
    onSubmit,
    onDeleteTagSelect,
    onChangeDate,
    RadioButtonChange,
    onChangeSceduler,
    dropdownListQuery,
    handleRepeatOptionChange,
    weekOfMonthOptionChange,
    monthOptionChange,
    weekDaysOptionChange,
    onChangeYear,
    onChangeHandler,
    DaysOfWeekOptionChange,
    WeekDaysOptionChange,
    OptionChange,
    handleDayClick,
    detailsStatusChangeHandler,
    onSubmitStatus,
    updateTemplateStatus,
    handleAlertClickOpen,
    onChangeMultipleDate,
    handleClickUpdateOpen,
    setTemplates,
    RemoveButtonHandeler,
    statusChangeHandler,
    handleScheduleOpen,
    StoreValuesHandler,
    categorylistQuery,
    selectChipCategory,
    recipelistQuery,
    handleRecipes,
    onChangeFilter,
    onSave,
    onUpdate,
    handleOpenClick,
    state,
    handleOptionChangeRecipe,
    handleOptionChangeRecipeBatch,
    optionHandleChangeBatches,
    optionHandleChangeRecipies,
    onChangeEffectiveDate,
    handleNameOpen,
    onChange,
    onNameSubmit,
    handleRegenerateOpenClick,
    onSearchChange,
    filterRecipeList,
    handleBack,
    onDeleteDetails,
    onNameCreateSubmit,
    generateBatch,
    createTemplateName,
    copyBatch,
    reGenerateBatch,
    searchHandler,
    updateTemplateName,
    storeLists,
    batchFilter1,
    forceUpdate,
    onSubmits: onSubmit,
    onSaveCustomDays,
    handleAddCustomDays,
    isCreateVisible,
    isDeletable,
    isCopyTemplate,
    isStatusChange,
    isEditable,
    isCreateSchedule,
    isStoreAssign,
    handleOpenClickDetailCopy,
    globalData,
    handleCustomFieldChange,
    handleClose,
    handleRemoveRecurringDate,
    confirmDeleteRecurringDates,
    tableData,
    moduleName,
    search,
  };
};
