import {
  Box,
  Button,
  Chip,
  Grow,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomeDialog, InputControl, Loader } from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import emptyIcon from "../../../assets/images/Icons/search-table.png";
import CloseIcon from "@mui/icons-material/Close";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SubCategoryProductsDialog } from "./SubCategoryProductsDialog";
import SearchIcon from "@mui/icons-material/Search";

export const CategoryAndSubCategoryTable = (props) => {
  const {
    handleClickOpen,
    onSelectItem,
    onDelete,
    searchHandler,
    category,
    isLoading,
    onDragEnd,
    onSubCategoryDragEnd,
    handleProductDialogOpen,
    ColorButton,
    onCategoryProductOrderChange,
    handleProductSortOrder,
    onSearchChange,
    recipeListQuery,
    isCreateVisible,
    isSubCategoryCreateVisible,
    isSubCategoryEditable,
    isSubCategoryDeletable,
    isEditable,
    isDeletable,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [handleClickOpenId, setHandleClickOpenId] = useState(0);
  const [isSubItem, setIsSubItem] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const onSearchFocusChange = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleClick = (event, id, isSubItem) => {
    setAnchorEl(event.currentTarget);
    setHandleClickOpenId(id);
    setIsSubItem(isSubItem);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHandleClickOpenId(0);
    setIsSubItem(isSubItem);
  };

  const onEditAction = () => {
    handleClickOpen(true, handleClickOpenId, isSubItem);
    setAnchorEl(null);
    setHandleClickOpenId(0);
  };

  const onDeleteAction = () => {
    onDelete(handleClickOpenId, isSubItem);
    setAnchorEl(null);
    setHandleClickOpenId(0);
  };

  return (
    <>
      <Box sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
        {isLoading ? (
          <Box sx={{ padding: "10px" }}>
            <Loader />
          </Box>
        ) : (
          <Stack direction="row">
            <Box
              sx={{
                width: "100%",
                borderRight: "1px solid #DFE8F2",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: "8px",
                    backgroundColor: "#F5F8FC",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    color="#111C2B"
                    sx={{
                      display: "flex",
                      gap: "4px",
                    }}
                  >
                    Category Name
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    {isSearchActive ? (
                      <InputControl
                        type="table-search"
                        placeholder="Search"
                        // className="fade-in"
                        endAdornment={
                          category.search && (
                            <InputAdornment
                              sx={{ cursor: "pointer" }}
                              position="end"
                            >
                              <CloseIcon
                                size="large"
                                onClick={() =>
                                  searchHandler({ target: { value: "" } })
                                }
                              />
                            </InputAdornment>
                          )
                        }
                        onChange={searchHandler}
                        value={category?.search}
                        onBlur={onSearchFocusChange}
                      />
                    ) : (
                      <SearchIcon
                        size="small"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            // backgroundColor: "#808080",
                          },
                        }}
                        onClick={onSearchFocusChange}
                      />
                    )}

                    {isCreateVisible && (
                      <Button
                        type="button"
                        size="small"
                        variant="text"
                        startIcon={<AddIcon />}
                        onClick={() => handleClickOpen(true)}
                      >
                        Category
                      </Button>
                    )}
                  </Box>
                </Stack>
              </Stack>
              {category?.item && category?.item.length > 0 ? (
                <Stack
                  sx={{
                    flexDirection: "row",
                  }}
                >
                  <Stack
                    sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
                  >
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable-list">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {category?.item?.map((item, index) => {
                              const selected =
                                category?.selectedItem.id === item?.id;
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Stack
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #DFE8F2",
                                        p: "9px 8px",
                                        cursor: "pointer",
                                        backgroundColor: selected && "#1760D2",
                                      }}
                                      onClick={() => onSelectItem(item, false)}
                                    >
                                      <Stack direction="row">
                                        <img src="/icons/ic_drag.svg" />
                                        <Typography
                                          sx={{ display: "inline" }}
                                          component="span"
                                          variant="subtitle2"
                                          color={selected ? "#fff" : "#111C2B"}
                                          lineHeight="18px"
                                          fontWeight={500}
                                        >
                                          {`${item.name}`}
                                        </Typography>
                                        {!!item.subcategories && (
                                          <Chip
                                            sx={{
                                              height: "20px",
                                              width: "20px",
                                              color: selected
                                                ? "#fff"
                                                : "#111C2B",
                                              ml: "4px",
                                              "& .MuiChip-label": {
                                                fontWeight: 500,
                                                p: 0,
                                              },
                                            }}
                                            variant="caption"
                                            label={item.subcategories}
                                          />
                                        )}
                                      </Stack>
                                      {(isEditable || isDeletable) && (
                                        <IconButton
                                          aria-label="more"
                                          sx={{ p: "0" }}
                                          onClick={(e) =>
                                            handleClick(e, item.id, false)
                                          }
                                        >
                                          <MoreHorizIcon
                                            sx={{
                                              color: selected ? "#fff" : "#000",
                                            }}
                                          />
                                        </IconButton>
                                      )}
                                    </Stack>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Stack>
                </Stack>
              ) : (
                <Stack alignItems="center" gap="8px" padding="32px 0">
                  <img src={emptyIcon} alt="empty" height={56} width={56} />
                  <Typography
                    sx={{ fontWeight: 400, fontSize: "14px", color: "#485364" }}
                  >
                    {category?.search
                      ? "No matches found"
                      : "There is no category added yet, You need to add category first"}
                  </Typography>
                </Stack>
              )}
            </Box>

            <Box
              sx={{
                width: "100%",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: "8px",
                    backgroundColor: "#F5F8FC",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    color="#111C2B"
                    sx={{
                      display: "flex",
                      gap: "4px",
                    }}
                  >
                    Sub Category Name
                  </Typography>
                  {isSubCategoryCreateVisible && (
                    <Button
                      type="button"
                      size="small"
                      variant="text"
                      disabled={category?.selectedItem?.id <= 0}
                      startIcon={<AddIcon />}
                      onClick={() => handleClickOpen(true, null, true)}
                    >
                      Sub Category
                    </Button>
                  )}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  flexDirection: "row",
                  // gap: "20px",
                }}
              >
                <Stack
                  sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}
                >
                  <DragDropContext onDragEnd={onSubCategoryDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {category?.selectedItem?.subData?.length ? (
                            category?.selectedItem?.subData?.map(
                              (data, index) => (
                                <Draggable
                                  key={data.id}
                                  draggableId={data.id.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <Stack
                                      onClick={() =>
                                        handleProductDialogOpen(true, data.id)
                                      }
                                    >
                                      <Stack
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          borderBottom: "1px solid #E6E6E6",
                                          p: "9px 8px",
                                          cursor: "pointer",
                                          backgroundColor: snapshot.isDragging
                                            ? "#f4f4f4"
                                            : "#fff",
                                        }}
                                        onClick={() => onSelectItem(data, true)}
                                      >
                                        <Stack direction="row">
                                          <img src="/icons/ic_drag.svg" />
                                          <Typography
                                            sx={{ display: "inline" }}
                                            component="span"
                                            variant="subtitle2"
                                            color="#111C2B"
                                            lineHeight="18px"
                                            fontWeight={500}
                                          >
                                            {`${data.name}`}
                                          </Typography>
                                          {data.recipeCount !== 0 && (
                                            <Chip
                                              sx={{
                                                height: "20px",
                                                width: "20px",
                                                color: "#111C2B",
                                                ml: "4px",
                                                "& .MuiChip-label": {
                                                  fontWeight: 500,
                                                  p: 0,
                                                },
                                              }}
                                              variant="caption"
                                              label={data.recipeCount}
                                            />
                                          )}
                                        </Stack>
                                        {(isSubCategoryEditable ||
                                          isSubCategoryDeletable) && (
                                          <IconButton
                                            aria-label="more"
                                            sx={{ p: "0" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleClick(e, data.id, true);
                                            }}
                                          >
                                            <MoreHorizIcon
                                              sx={{ color: "#000" }}
                                            />
                                          </IconButton>
                                        )}
                                      </Stack>
                                    </Stack>
                                  )}
                                </Draggable>
                              )
                            )
                          ) : (
                            <Stack
                              alignItems="center"
                              gap="8px"
                              padding="32px 0"
                            >
                              <img
                                src={emptyIcon}
                                alt="empty"
                                height={56}
                                width={56}
                              />
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "#485364",
                                }}
                              >
                                {category?.search
                                  ? "No matches found"
                                  : "There is no subcategory added yet, You need to add category first"}
                              </Typography>
                            </Stack>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                      "& .MuiMenu-paper": {
                        minWidth: "188px",
                        boxShadow: "none",
                        border: "1px solid #E6EEF7",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {(isSubCategoryEditable || isEditable) && (
                      <MenuItem
                        className="popover-menuitem"
                        onClick={onEditAction}
                      >
                        <img alt="edit" src="/icons/ic_edit.svg" />
                        Edit
                      </MenuItem>
                    )}
                    {(isSubCategoryDeletable || isDeletable) && (
                      <MenuItem
                        className="popover-menuitem"
                        onClick={onDeleteAction}
                      >
                        <img alt="delete" src="/icons/ic_trash.svg" />
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        )}
      </Box>
      <CustomeDialog
        PaperProps={{
          sx: {
            minWidth: "650px",
            maxHeight: "500px",
            minHeight: "200px",
          },
        }}
        closeIcon={false}
        open={category.isProductDialog}
        handleClose={(e) => {
          handleProductDialogOpen(false);
        }}
        content={
          <SubCategoryProductsDialog
            handleModal={handleProductDialogOpen}
            ColorButton={ColorButton}
            productsList={recipeListQuery?.data?.data}
            onCategoryProductOrderChange={onCategoryProductOrderChange}
            handleProductSortOrder={handleProductSortOrder}
            onSearchChange={onSearchChange}
            recipeListQuery={recipeListQuery}
            category={category}
          />
        }
      />
    </>
  );
};
