import { Chip, Stack, Typography } from "@mui/material";
import React from "react";

export const SelectChip = ({ value, isSelected, onClick }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "32px",
        gap: "8px",
        border: "1px solid #DFE8F2",
        p: "6px 8px 6px 10px",
        bgcolor: isSelected ? "#1760D2" : "transparent",
        color: isSelected ? "#fff" : "inherit",
        minWidth: "fit-content",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Typography variant="subtitle2" fontWeight={500} noWrap>
        {value.name}
      </Typography>
      <Chip
        size="small"
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          bgcolor: isSelected ? "#124da8" : "#e1e1e1",
          color: isSelected ? "#fff" : "inherit",
        }}
        label={
          value.recipeCount_template
            ? value.recipeCount_template
            : value.recipeListCount || 0
        } // Ensure 0 is displayed properly
      />
    </Stack>
  );
};
