import { useImmer } from "use-immer";
import { useEffect } from "react";
import { useAssignedStoreUpdate } from "./useAssignedStoreUpdate";
import { useDeleteStoreFromRecipeQueries } from "./useDeleteStoreFromRecipeQueries";
import { useDelete, usePermission } from "../../../shared";
import { useAssignedStoreList } from "./useAssignedStoreList";

export const useStoreDetails = ({ recipeId }) => {
  const {
    assignedStoreState,
    onSearchHandler,
    pagination,
    setPagination,
    assingedStoreListQuery,
    sorting,
    setSorting,
    onClearSearch,
  } = useAssignedStoreList({ recipeId });
  const isStoreAssign = usePermission("recipe-assign_store");
  const isEditable = usePermission("recipe-modify");
  const { updateRecipeStoreMuate } = useAssignedStoreUpdate();

  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();

  const { mutateDeleteRecipeStore } = useDeleteStoreFromRecipeQueries({
    resetDeleteInfo,
  });

  const [state, setState] = useImmer({
    selectStoreDialogDetails: {
      isOpenStoreDialog: false,
      selectedStores: [],
      alreadySelectedStores: [],
    },
  });

  useEffect(() => {
    if (assignedStoreState.alreadyAssignedStoreList) {
      setState((draft) => {
        draft.selectStoreDialogDetails.selectedStores =
          assignedStoreState.alreadyAssignedStoreList;
        draft.selectStoreDialogDetails.alreadySelectedStores =
          assignedStoreState.alreadyAssignedStoreList;
      });
    }
  }, [assignedStoreState.alreadyAssignedStoreList]);

  const handleModal = (type, state, data) => {
    switch (type) {
      case "store-dialog-add":
        setState((draft) => {
          draft.selectStoreDialogDetails.isOpenStoreDialog = state;
        });
        break;
    }
  };

  const handleStoreDialogSelect = (v, e) => {
    const isBulk = Array.isArray(v);
    if (isBulk) {
      const subRowsID = v.flatMap((item) =>
        item.stores ? item.stores.map((s) => s.id) : [item.id]
      );

      if (e.target.checked) {
        setState((draft) => {
          draft.selectStoreDialogDetails.selectedStores = [
            ...new Set([
              ...state.selectStoreDialogDetails.selectedStores,
              ...subRowsID,
            ]),
          ];
        });
      } else {
        setState((draft) => {
          draft.selectStoreDialogDetails.selectedStores =
            state?.selectStoreDialogDetails?.alreadySelectedStores?.filter(
              (val) =>
                !subRowsID.includes(val) ||
                state?.selectStoreDialogDetails?.alreadySelectedStores?.includes(
                  val
                )
            );
        });
      }
    } else {
      if (v.stores) {
        const subRowsID = v.stores?.map((e) => e.id); // Get all store IDs in the state
        if (e.target.checked) {
          setState((draft) => {
            draft.selectStoreDialogDetails.selectedStores = [
              ...new Set([
                ...state.selectStoreDialogDetails.selectedStores,
                ...subRowsID,
              ]),
            ];
          });
        } else {
          setState((draft) => {
            draft.selectStoreDialogDetails.selectedStores =
              draft.selectStoreDialogDetails.selectedStores.filter(
                (val) =>
                  !subRowsID.includes(val) ||
                  state.selectStoreDialogDetails.alreadySelectedStores.includes(
                    val
                  )
              );
          });
        }
      } else {
        const isSelected =
          state.selectStoreDialogDetails.selectedStores.includes(v.id);
        if (isSelected) {
          setState((draft) => {
            draft.selectStoreDialogDetails.selectedStores =
              draft.selectStoreDialogDetails.selectedStores.filter(
                (val) => val !== v.id
              );
          });
        } else {
          setState((draft) => {
            draft.selectStoreDialogDetails.selectedStores = [
              ...draft.selectStoreDialogDetails.selectedStores,
              v.id,
            ];
          });
        }
      }
    }
  };

  const handleStoreToRecipe = () => {
    updateRecipeStoreMuate.mutate({
      recipeId: recipeId,
      payload: state.selectStoreDialogDetails.selectedStores,
      handleModal,
    });
  };

  const confirmDelete = () => {
    mutateDeleteRecipeStore.mutate({ recipeId, storeId: deleteInfo.id });
  };

  return {
    state,
    assignedStoreState,
    handleModal,
    handleStoreDialogSelect,
    handleStoreToRecipe,
    onSearchHandler,
    confirmDelete,
    pagination,
    setPagination,
    assingedStoreListQuery,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    sorting,
    setSorting,
    isLoading: assingedStoreListQuery.isLoading,
    onClearSearch,
    isStoreAssign,
    isEditable,
    setState,
  };
};
