import * as React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { InputControl, Loader } from "../../../shared";

export const RecipeLabelCreateOrEdit = ({
  handleCreateOrEditModal,
  onCreateSubmit,
  labelPrinting,
  isLabelprintingList,
  globalData,
  labelPrintingEditTypeChangeHandler,
  onEditChange,
  validator,
  basicDetailsQuery,
  labelPrintingDetails,
  recipeListQuery,
  labelPrintingRecipeEditChangeHandler,
}) => {
  const {
    defrosting_time,
    freezer_life,
    item_id,
    item_name,
    type_id,
    variant_name,
  } = labelPrinting?.editableData;

  return labelPrintingDetails?.isPending && labelPrinting?.id ? (
    <Loader />
  ) : (
    <>
      <Stack
        justifyContent="space-between"
        height="100%"
        gap="12px"
        maxHeight={"calc(100% - 24px)"}
        mt="32px"
      >
        <InputControl
          type="dropdown"
          required
          fullWidth
          disabled
          options={globalData?.labelPrinting_types ?? []}
          value={
            globalData?.labelPrinting_types?.find(
              (value) => value.value === type_id
            ) || null
          }
          onChange={(event, option) =>
            labelPrintingEditTypeChangeHandler("type_id", option)
          }
          getOptionLabel={(option) => option?.label || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              error={validator.current.message("Type", type_id, "required")}
              helperText={validator.current.message(
                "Type",
                type_id,
                "required"
              )}
            />
          )}
        />
        {type_id === 3 ? (
          <InputControl
            disabled={!isLabelprintingList}
            type="number"
            required
            name="item_name"
            sx={{
              selfAlign: "right",
              width: "100%",
            }}
            value={item_name}
            onChange={(e) => {
              onEditChange(e);
            }}
            label="Item Name"
            error={validator.current.message(
              "Item Name",
              item_name,
              "required"
            )}
            helperText={validator.current.message(
              "Item Name",
              item_name,
              "required"
            )}
          />
        ) : isLabelprintingList ? (
          type_id === 2 ? (
            <InputControl
              type="text"
              disabled
              required
              name="item_name"
              sx={{
                selfAlign: "right",
                width: "100%",
              }}
              value={item_name}
              label="Item Name"
              error={validator.current.message(
                "Item Name",
                item_name,
                "required"
              )}
              helperText={validator.current.message(
                "Item Name",
                item_name,
                "required"
              )}
            />
          ) : (
            <InputControl
              type="text"
              disabled
              required
              name="item_name"
              sx={{
                selfAlign: "right",
                width: "100%",
              }}
              value={item_name}
              label="Item Name"
              error={validator.current.message(
                "Item Name",
                item_name,
                "required"
              )}
              helperText={validator.current.message(
                "Item Name",
                item_name,
                "required"
              )}
            />
          )
        ) : (
          <InputControl
            disabled={!isLabelprintingList}
            type="number"
            required
            name="item_name"
            sx={{
              selfAlign: "right",
              width: "100%",
            }}
            value={basicDetailsQuery?.data?.name}
            label="Item Name"
            error={validator.current.message(
              "Item Name",
              basicDetailsQuery?.data?.name,
              "required"
            )}
            helperText={validator.current.message(
              "Item Name",
              basicDetailsQuery?.data?.name,
              "required"
            )}
          />
        )}

        <InputControl
          name="variant_name"
          label="Variant Name"
          onChange={(e) => {
            onEditChange(e);
          }}
          value={variant_name}
          sx={{
            width: "100%",
            bgcolor: "#fff",
          }}
        />
        <InputControl
          name="defrosting_time"
          label="Defrosting Time Hrs"
          onChange={(e) => {
            onEditChange(e);
          }}
          onBlur={(e) => {
            onEditChange(e);
          }}
          value={defrosting_time}
          sx={{
            width: "100%",
            bgcolor: "#fff",
          }}
        />
        <InputControl
          name="freezer_life"
          label="Coolroom/Freezer Life Hrs"
          onChange={(e) => {
            onEditChange(e);
          }}
          onBlur={(e) => {
            onEditChange(e);
          }}
          value={freezer_life}
          sx={{
            width: "100%",
            bgcolor: "#fff",
          }}
        />
        <Stack direction="row" gap={1} justifyContent="flex-end">
          <Button
            sx={{ height: "40px", width: "33px", alignSelf: "end" }}
            variant="outlined"
            onClick={() => {
              handleCreateOrEditModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ height: "40px", width: "33px", alignSelf: "end" }}
            variant="contained"
            onClick={onCreateSubmit}
          >
            {false ? "Update" : "Save"}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
