import { Box, Stack, useMediaQuery } from "@mui/material";
import version from "../../../../package.json";
import { ProfileCard, SAStoreSelection, SidebarMenu } from ".";
import DashBoardIcon from "../../../assets/images/Icons/Dashboard.svg";
import DeviceIcon from "../../../assets/images/Icons/DeviceIcon.svg";
import Store from "../../../assets/images/Icons/ic_store.svg";
import Tips from "../../../assets/images/Icons/Tips.svg";
import Logo from "../../../assets/images/Logo/Logo.svg";
import { useLocation } from "react-router-dom";
import User from "../../../assets/images/Icons/ic_user.svg";
import MasterdataIcon from "../../../assets/images/Icons/ic_masterdata.svg";
import Label from "../../../assets/images/Icons/ic_label.svg";
import IngredientIcon from "../../../assets/images/Icons/ic_ingredient.svg";
import RecipeIcon from "../../../assets/images/Icons/ic_recipe.svg";
import TemplatesIcon from "../../../assets/images/Icons/ic_templates.svg";
import ProductionChartIcon from "../../../assets/images/Icons/ic_production_chart.svg";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../store";

export const DashboardSidebar = (props) => {
  const { open, onClose, onCloseAndOpen } = props;
  const { setDashboardSidebarNavigation, tableData } = useContext(AppContext);
  const onChangeNavigation = (value) => {
    setDashboardSidebarNavigation({
      value: value,
    });
  };
  useEffect(() => {
    if (tableData.isSidebarOpen) {
      onCloseAndOpen(tableData.isSidebarOpen);
    }
  }, [tableData.isSidebarOpen]);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const items = [
    {
      href: "/",
      icon: <img alt="icon-Dashboard" alignment="center" src={DashBoardIcon} />,
      title: "Dashboard",
      permission: "dashboard-view",
      storeAdmin: true,
    },
    {
      icon: (
        <img alt="icon-masterdataj" alignment="center" src={MasterdataIcon} />
      ),
      href: "/master-data",
      title: "Master Data",
      permission: [
        "category-view",
        "unit-view",
        "batchgroup-view",
        "wastage-chart-view",
      ],
      storeAdmin: true,
    },
    {
      icon: (
        <img alt="icon-ingredient" alignment="center" src={IngredientIcon} />
      ),
      href: "/ingredients",
      title: "Ingredients",
      permission: ["ingredient-view"],
      storeAdmin: false,
    },
    {
      icon: <img alt="icon-recipe" alignment="center" src={RecipeIcon} />,
      href: "/recipe",
      title: "Recipe",
      permission: ["recipe-view"],
      storeAdmin: false,
    },
    {
      icon: <img alt="icon-template" alignment="center" src={TemplatesIcon} />,
      href: "/templates",
      title: "Templates",
      permission: ["template-view"],
      storeAdmin: true,
    },
    {
      icon: <img alt="icon-pa" alignment="center" src={ProductionChartIcon} />,
      href: "/production-charts",
      title: "Production Charts",
      permission: ["production-chart-view"],
      storeAdmin: true,
    },
    {
      href: "/tips-tricks",
      icon: <img alt="icon-Dashboard" alignment="center" src={Tips} />,
      title: "Tips & Tricks",
      permission: ["Tips&Tricks-view"],
      storeAdmin: false,
    },
    {
      href: "/devices",
      icon: <img alt="icon-Dashboard" alignment="center" src={DeviceIcon} />,
      title: "Devices",
      permission: ["device-view"],
      storeAdmin: false,
    },
    {
      href: "/store",
      icon: <img alt="icon-store" alignment="center" src={Store} />,
      title: "Stores",
      permission: ["store-view"],
      storeAdmin: false,
    },
    {
      href: "/label-printing",
      icon: <img alt="icon-label" alignment="center" src={Label} />,
      title: "Label Printing",
      permission: "label-printing-view",
      storeAdmin: false,
    },
    {
      icon: <img alt="icon-user" alignment="center" src={User} />,
      title: "Users",
      storeAdmin: false,
      subMenu: [
        {
          href: "/manage-users",
          title: "Manage Users",
          permission: ["user-view"],
          storeAdmin: false,
        },
        {
          href: "/roles-and-permissions",
          title: "Roles & Permissions",
          permission: ["role-view"],
          storeAdmin: false,
        },
      ],
    },
  ];

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            py: "16px",
            minHeight: "64px",
          }}
        >
          <img
            alt="logo"
            alignment="center"
            src={Logo}
            style={{
              maxWidth: !open ? "40px" : "",
            }}
          />
        </Box>
        {isStoreAdmin ? (
          <SAStoreSelection collapse={open} />
        ) : (
          <ProfileCard collapse={open} />
        )}
        <Stack sx={{ padding: "0 12px", mb: "8px", gap: "4px" }}>
          {items.map((item, index) => {
            return (
              <SidebarMenu
                item={item}
                index={index}
                key={index}
                collapse={open}
              />
            );
          })}
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "30px",
            fontSize: "14px",
            textAlign: "center",
            margin: "auto 0 8px",
          }}
        >
          {isStoreAdmin && <ProfileCard collapse={open} />}
          Version {version.version}
        </Box>
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#5E7360",
        color: "#FFFFFF",
        width: open ? 240 : 65,
        zIndex: 1200,
        position: "fixed",
        top: "0px",
        left: "0px",
        height: "100%",
        transition: "width .7s ease",
      }}
      onMouseEnter={() => onCloseAndOpen(true)}
      onMouseLeave={() => !tableData.isSidebarOpen && onCloseAndOpen(false)}
    >
      <Stack
        sx={{
          position: "absolute",
          top: "12px",
          right: "-10px",
          alignItems: "center",
          justifyContent: "center",
          background: tableData.isSidebarOpen ? "#A04D3D" : "#564845",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          transition: "transform .7s ease",
          transform: open ? "scale(1)" : "scale(0)",
          zIndex: 1201,
        }}
        onClick={() => onChangeNavigation(!tableData.isSidebarOpen)}
      >
        <PushPinIcon sx={{ height: "14px", rotate: "-45deg" }} />
      </Stack>
      <Stack
        anchor="left"
        className="sidebar-drawer"
        sx={{
          backgroundColor: "#5E7360",
          color: "#FFFFFF",
          width: open ? 240 : 65,
          zIndex: 1200,
          position: "fixed",
          top: "0px",
          left: "0px",
          height: "100%",
          overflowY: "auto",
          transition: "width .7s ease",
        }}
      >
        {content}
      </Stack>
    </Box>
  );
};
