import {
  Box,
  Checkbox,
  Chip,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
  Link as Links,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useMemo } from "react";
import {
  DashBoardDateRangePicker,
  InputControl,
  PageListLayout,
  ServerPaginatedtable,
} from "../../../shared";
import { createColumnHelper } from "@tanstack/react-table";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useProductionChart } from "../hooks/useProductionChart";
import { addDays } from "date-fns";
import moment from "moment";
import { navigateBasedOnUserType } from "../../../utils";

export const ProductionChartList = () => {
  const {
    productionchart,
    listQuery: { isLoading, data },
    dataList,
    setPagination,
    pagination,
    sorting,
    setSorting,
    searchHandler,
    storeList,
    handleStoreSelect,
    onChangeDate,
    onChangeDateFuture,
    handleDateChangeApply,
    handleIsOpen,
    handleIsOpenFuture,
    handleViewCharts,
    onClearFilter,
    disableclearButton,
    isFututeChartVisble,
  } = useProductionChart({ list: true });

  const navigate = useNavigate();
  const filter = createFilterOptions();

  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");

  const columnHelper = createColumnHelper(data?.data ?? []);
  const ActionButtons = ({ row }) => {
    const date = moment(row.original.date, "DD-MMM-YYYY");
    return (
      <div className="action-buttons">
        <Stack
          direction="row"
          gap={1}
          justifyContent="flex-end"
          alignItems="center"
          width="max-content"
          ml="auto"
        >
          {!isStoreAdmin && (
            <Tooltip
              placement="bottom"
              arrow
              title={row.original.store_name
                .map((val) => {
                  return val.name;
                })
                .join(", ")}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img
                alt="edit"
                src="/icons/sm-home.png"
                style={{ padding: "2px" }}
              />
              <Typography
                lineHeight="16px"
                color="#657083"
                fontWeight={400}
                fontSize="14px"
              >
                {row.original.store_count} Stores
              </Typography>
            </Tooltip>
          )}
          <>
            {!isStoreAdmin && (
              <Typography
                lineHeight="16px"
                color="#657083"
                fontWeight={400}
                fontSize="18px"
              >
                &#183;
              </Typography>
            )}
            <img
              alt="edit"
              src="/icons/ic_batch_box.png"
              style={{ width: "18px", height: "18px" }}
            />
            <Typography
              lineHeight="16px"
              color="#657083"
              fontWeight={400}
              fontSize="14px"
            >
              {row.original.batch_count} Batches
            </Typography>
          </>
          <Typography
            lineHeight="16px"
            color="#657083"
            fontWeight={400}
            fontSize="18px"
          >
            &#183;
          </Typography>
          <Typography
            lineHeight="16px"
            color={
              moment(date).isSameOrAfter(new Date(), "day") ? "#000" : "#657083"
            }
            fontWeight={400}
            fontSize="14px"
          >
            {date.isSame(moment(), "day")
              ? "Today"
              : date.isSame(moment().add(1, "days"), "day")
              ? "Tomorrow"
              : row.original.date}
          </Typography>
          <Link to={`/production-charts/details/${row.original.date}`}>
            <img alt="details" src="/icons/arrow_forward.png" />
          </Link>
        </Stack>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("templates", {
        header: "",

        cell: ({ row }) => {
          let tags = row.original.templates;
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                alignItems: "center",
              }}
            >
              {tags?.slice(0, 8).map((data, i) => (
                <Chip
                  key={i}
                  sx={{
                    cursor: "pointer",
                    borderColor: "#C4CFE4",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#1760D2",
                  }}
                  label={data.name}
                  variant="outlined"
                  size="small"
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigateBasedOnUserType(
                      `/templates/details/${data.id}`,
                      isStoreAdmin,
                      navigate
                    );
                  }}
                />
              ))}
              {tags?.length > 8 && (
                <Tooltip
                  placement="bottom"
                  arrow
                  title={
                    <>
                      {tags?.slice(8, tags?.length).map((val, index) => (
                        <div key={index}>{val.name}</div>
                      ))}
                    </>
                  }
                >
                  <Chip
                    sx={{
                      borderRadius: "15px",
                      height: "20px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    label={`+${tags?.length - 8} More`}
                    variant="outlined"
                    size="small"
                    color="gray2"
                  />
                </Tooltip>
              )}
            </Box>
          );
        },
        enableSorting: false,
        enableSorting: false,
      }),

      columnHelper.display({
        id: "actions",
        cell: ({ row }) => <ActionButtons row={row} />,
      }),
    ],
    []
  );

  const breadcrumbs = [
    <Typography key="1" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Production Chart
    </Typography>,
  ];

  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <ServerPaginatedtable
                defaultData={data?.data ?? []}
                columns={columns}
                onSearchChange={searchHandler}
                search={productionchart.filters.search}
                sorting={sorting}
                setSorting={setSorting}
                pagination={pagination}
                paginationProps={data?.pagination || { total: 0, lastPage: 1 }}
                setPagination={setPagination}
                headerText={"Production Chart"}
                isLoading={isLoading}
                onRowLink={(e) => {
                  return `details/${e.date}`;
                }}
                rightContent={
                  <Stack direction="row" gap="8px">
                    {isFututeChartVisble && (
                      <DashBoardDateRangePicker
                        onChangeDate={onChangeDateFuture}
                        range={productionchart.futureTempdate}
                        showRange={[]}
                        isOpen={productionchart.isDatePickerOpenfuture}
                        handleIsOpen={handleIsOpenFuture}
                        handleDateChangeApply={handleViewCharts}
                        text={"Future Charts"}
                        buttonText="View Charts"
                        minDate={addDays(new Date(), 7)}
                      />
                    )}
                  </Stack>
                }
                tableHeaderContent={
                  <Stack
                    sx={{
                      flexDirection: "row",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    {!isStoreAdmin && (
                      <InputControl
                        type="dropdown"
                        fullWidth
                        multiple={true}
                        options={storeList?.data || []}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        disableCloseOnSelect
                        sx={{
                          width: "230px",
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => <></>)
                        }
                        value={productionchart.filters.stores}
                        onChange={(e, v) => handleStoreSelect(e, v)}
                        renderOption={(props, option, { selected }) => (
                          <>
                            <li {...props} key={option.id}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          </>
                        )}
                        filterOptions={filter}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Store" />
                        )}
                      />
                    )}
                    <DashBoardDateRangePicker
                      onChangeDate={onChangeDate}
                      range={productionchart?.tempdate}
                      showRange={productionchart?.filters?.date}
                      isOpen={productionchart.isDatePickerOpen}
                      handleIsOpen={handleIsOpen}
                      handleDateChangeApply={handleDateChangeApply}
                      text={"Date"}
                      isInverted={true}
                      maxDate={addDays(new Date(), 7)}
                    />
                    <Button
                      type="button"
                      size="small"
                      variant="text"
                      sx={{
                        p: "6px 12px 6px 12px",
                        gap: "6px",
                        height: "36px",
                        mr: "6px",
                      }}
                      disabled={!disableclearButton}
                      onClick={onClearFilter}
                    >
                      Clear All
                    </Button>
                  </Stack>
                }
              />
            </Stack>
          </>
        }
      />
    </>
  );
};
