import { API } from "../../../utils/api";

export const saveBasicdetails = async (payload) => {
  const { formData } = payload;
  const res = await API.post("recipes/basic-details", formData);
  return res.data.data;
};

export const saveImageUrl = async (payload) => {
  const res = await API.post("recipes/media", payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data.url;
};
export const saveTumpnailImageUrl = async (payload) => {
  const res = await API.post("recipes/thumb-media", payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data.url;
};
export const editBasicDetails = async (data) => {
  const { detailsId, formData } = data;
  const res = await API.post(`recipes/basic-details/${detailsId}`, formData);
  return res.data;
};

export const getBasicDetails = async (id) => {
  const res = await API.get(`recipes/basic-details/${id}`);
  return res.data.data;
};
