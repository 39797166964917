import {
  Box,
  Button,
  Chip,
  FormHelperText,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  CustomTabPanel,
  CustomeDialog,
  InputControl,
  Loader,
  PageListLayout,
} from "../../../shared";
import { styled } from "@mui/material/styles";
import { useProductionChart } from "../hooks/useProductionChart";
import { ProductionChartBatchesDetails } from "../components/ProductionChartBatchesDetails";
import { ProductionChartRecipeDetails } from "../components/ProductionChartRecipeDetails";
import { EditProductionChart } from "../components/EditProductionChart";
import { ProductionChartTemplateDialog } from "../components/ProductionChartTemplateDialog";
import { StoreListCard } from "../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { CustomDialogTemplate } from "../../../shared/components/CustomDialogTemplate";
import SimpleReactValidator from "simple-react-validator";
import { navigateBasedOnUserType } from "../../../utils";

export const ProductionChartDetails = () => {
  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        Item: {
          message: "The recipes field is required.",
          required: true,
          rule: (value) => {
            if (value.length <= 0) return false;
            else return true;
          },
        },
      },
    })
  );
  const { date } = useParams();
  const navigate = useNavigate();
  const {
    productionChartDetails: { data },
    editTemplate,
    handleModal,
    productionchart,
    handleTemplateModal,
    handleSelectStore,
    onSearchChange,
    categorylistQuery,
    handleCustomTableChange,
    handleRecipes,
    onChangeFilter,
    onTemplateSearchChange,
    updateTableData,
    onStoreChange,
    onUpdate,
    handleTemplateChange,
    convertedData,
    isEditable,
    handleCustomFieldChange,
    recipelistQuery,
  } = useProductionChart({
    detId: moment(date)?.format("YYYY-MM-DD"),
  });

  const { details, selectedStore, isLoading } = productionchart;
  const optionsStoreList = data?.stores?.map((item) => ({
    name: item.store_name,
    id: item.store_id,
  }));
  const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#F5F8FC",
    color: "black",
    width: "87px",
    height: "36px",
    "&:hover": {
      backgroundColor: "#F5F8FC",
    },
  }));
  const [item, setItem] = React.useState(0);
  const handleChangeTemplates = (event, newValue) => {
    setItem(newValue);
  };
  const [value, setValue] = useState(0);

  const handleChange = (e, v) => {
    setValue(v);
  };
  const onUpdateSubmit = () => {
    if (validator.current.allValid()) {
      onUpdate();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  const batchesLength = selectedStore?.batches?.length || 0;
  const recipesLength = selectedStore?.other_recipes?.length || 0;
  const breadcrumbs = [
    <Link
      underline="none"
      key="1"
      color="#1760D2"
      onClick={(e) =>
        navigateBasedOnUserType("/production-charts", isStoreAdmin, navigate)
      }
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      Production Chart
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: "14px", fontWeight: 400 }}
    >
      {data?.date || ""}
    </Typography>,
  ];
  const getBatchesLengthStyles = () => {
    if (value === 0) {
      return {
        backgroundColor: "white",
        color: "black",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    } else {
      return {
        backgroundColor: "black",
        color: "white",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    }
  };

  const getRecipeLengthStyles = () => {
    if (value === 1) {
      return {
        backgroundColor: "white",
        color: "black",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    } else {
      return {
        backgroundColor: "black",
        color: "white",
        padding: ".5px 6px",
        borderRadius: "10px",
      };
    }
  };
  return (
    <>
      <PageListLayout
        breadcrumbs={breadcrumbs}
        isChanged={true}
        pageContent={
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  height="62px"
                  bgcolor="white"
                >
                  <Stack alignSelf="center" direction="row" gap={2} ml={3}>
                    <Typography variant="h6" fontWeight={600} fontSize="24px">
                      {data?.date}
                    </Typography>
                    <Box mt={0.5}>
                      {data?.status ? (
                        <Chip
                          size="small"
                          color="primary"
                          variant="filled"
                          label="COMPLETED"
                          sx={{ height: "22px" }}
                        />
                      ) : (
                        <Chip
                          size="small"
                          label="GENERATED"
                          sx={{
                            color: "#0E7090",
                            background: "#06AED41F",
                            fontWeight: 600,
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                  <Stack direction="row" gap={2} alignSelf="center" mr={3}>
                    <ColorButton onClick={() => handleTemplateModal(true, 0)}>
                      Template
                    </ColorButton>
                    {isEditable && (
                      <>
                        {!data?.status ? (
                          <img
                            alt="edit"
                            onClick={() => handleModal(true, 0)}
                            src="/icons/ic_edit_sm.png"
                            style={{
                              cursor: "pointer",
                              height: "20px",
                              marginTop: 6,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Stack>
                </Stack>

                <Grid container spacing={2}>
                  {!isStoreAdmin && (
                    <StoreListCard
                      storeList={data?.stores}
                      selectedStore={selectedStore}
                      onSelect={handleSelectStore}
                      productionchart={productionchart}
                    />
                  )}

                  <Grid
                    item
                    xs={isStoreAdmin ? 12 : 6}
                    md={isStoreAdmin ? 12 : 8}
                  >
                    <Stack
                      sx={{ borderRadius: "4px", bgcolor: "#fff", px: "16px" }}
                    >
                      <Stack flexDirection="row" justifyContent="space-between">
                        <Tabs
                          className="tabs"
                          value={value}
                          onChange={handleChange}
                        >
                          <Tab
                            label={
                              <Stack direction="row" gap="10px">
                                Batches
                                <span style={getBatchesLengthStyles()}>
                                  {batchesLength}
                                </span>
                              </Stack>
                            }
                          />
                          <Tab
                            label={
                              <Stack direction="row" gap="10px">
                                Recipes
                                <span style={getRecipeLengthStyles()}>
                                  {recipesLength}
                                </span>
                              </Stack>
                            }
                          />
                        </Tabs>
                        <img
                          alt="details"
                          src="/icons/ic_print.png"
                          style={{
                            height: "20px",
                            marginTop: 6,
                          }}
                        />
                      </Stack>
                      <CustomTabPanel value={value} index={0} border={false}>
                        <ProductionChartBatchesDetails
                          data={selectedStore?.batches}
                        />
                      </CustomTabPanel>
                      <CustomTabPanel value={value} index={1} border={false}>
                        <ProductionChartRecipeDetails
                          data={selectedStore?.other_recipes}
                        />
                      </CustomTabPanel>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        }
      />
      <CustomDialogTemplate
        PaperProps={{
          sx: {
            minWidth: "993px",
            minHeight: "600px",
          },
        }}
        closeIcon={false}
        open={productionchart.isOpen}
        title={
          <Stack direction="row" gap={4}>
            <Typography alignContent="center" width="100%">
              {productionchart?.templateDate}
            </Typography>
            <InputControl
              label="Tax Group"
              sx={{
                width: "100%",
                minWidth: 230,
              }}
              type="dropdown"
              disableClearable
              required
              disabled={isStoreAdmin}
              options={optionsStoreList ?? []}
              getOptionLabel={(option) => option?.name}
              value={
                (optionsStoreList?.length > 0 &&
                  productionchart?.store &&
                  optionsStoreList?.find(
                    (c) => c.id === productionchart?.store
                  )) ||
                null
              }
              onChange={(e, newValue) => {
                onStoreChange({
                  target: {
                    name: "store",
                    value: newValue?.id,
                    index: 0,
                  },
                  data: data?.stores,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Stores"
                  required
                  error={validator.current.message(
                    "Store",
                    productionchart?.store,
                    "required"
                  )}
                  helperText={validator.current.message(
                    "Store",
                    productionchart?.store,
                    "required"
                  )}
                />
              )}
            />
          </Stack>
        }
        middleContent={
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                bgcolor: "#fff",
              }}
            />
            <Stack
              direction="row"
              alignItems="center"
              bgcolor="#DFE8F2"
              height="50px"
            >
              <Tabs value={item} onChange={handleChangeTemplates}>
                {productionchart?.dataList?.map((i, index) => {
                  return (
                    <Tab
                      label={i?.template_name}
                      onClick={() => {
                        handleTemplateChange(
                          index,
                          i?.template_id,
                          i.recipeList
                        );
                      }}
                    />
                  );
                })}
              </Tabs>
            </Stack>
          </>
        }
        content={
          <EditProductionChart
            handleCustomTableChange={handleCustomTableChange}
            productionchart={productionchart}
            categorylistQuery={categorylistQuery}
            handleRecipes={handleRecipes}
            onChangeFilter={onChangeFilter}
            updateTableData={updateTableData}
            onSearchChange={onTemplateSearchChange}
            handleCustomFieldChange={handleCustomFieldChange}
            recipelistQuery={recipelistQuery}
          />
        }
        tableFooter={
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            padding="10px"
          >
            <Box sx={{ alignContent: "center" }}>
              <FormHelperText sx={{ color: "#C60808" }}>
                {validator.current.message(
                  "Recipe field",
                  convertedData,
                  "Item"
                )}
              </FormHelperText>
            </Box>
            <Button variant="outlined" onClick={() => handleModal(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={editTemplate?.isPending}
              onClick={() => onUpdateSubmit()}
            >
              Regenerate Batches
            </Button>
          </Stack>
        }
      />
      <CustomeDialog
        PaperProps={{
          sx: {
            minWidth: "650px",
            height: "750px",
          },
        }}
        closeIcon={false}
        open={productionchart.isTemplateOpen}
        handleClose={(e) => {
          handleTemplateModal(false);
        }}
        content={
          <ProductionChartTemplateDialog
            handleModal={handleTemplateModal}
            ColorButton={ColorButton}
            templateList={productionchart.tempateList}
            onSearchChange={onSearchChange}
          />
        }
      />
    </>
  );
};
