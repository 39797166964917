import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  PageListLayout,
  ServerPaginatedtable,
  SimpleMenu,
  InputControl,
  CustomeDialog,
} from "../../../shared";
import AddIcon from "@mui/icons-material/Add";
import { createColumnHelper } from "@tanstack/react-table";
import { useLabelPrinting } from "../hooks/useLabelPrinting";
import leaf from "../../../assets/images/Icons/ingredient.svg";
import book from "../../../assets/images/Icons/Cookbook.svg";
import Others from "../../../assets/images/Icons/Others.svg";
import {
  CreateOrEditLabelPrintingDialog,
  LabelPrintDeleteAlertDialog,
} from "../components";
import { CustomDialogTemplate } from "../../../shared/components/CustomDialogTemplate";
import { RecipeLabelCreateOrEdit } from "../../Recipe/components/RecipeLabelCreateOrEdit";
import { useNavigate } from "react-router-dom";
import { convertStringToTime } from "../../../utils";

export const LabelPrintingList = () => {
  const {
    labelPrinting,
    listQuery,
    setSorting,
    tableData,
    setPagination,
    moduleName,
    sorting,
    pagination,
    search,
    handleCreateOrEditModal,
    addNewRecipeRow,
    handleDelete,
    globalData,
    labelPrintingTypeChangeHandler,
    ingredientListQuery,
    recipeListQuery,
    onChange,
    onCreateSubmit,
    labelPrintingChangeHandler,
    onDelete,
    confirmDelete,
    resetDeleteInfo,
    deleteInfo,
    selectType,
    onSearchHandler,
    onClearFilter,
    disableclearButton,
    basicDetailsQuery,
    labelPrintingEditTypeChangeHandler,
    labelPrintingEditChangeHandler,
    onEditChange,
    validator,
    isCreateVisible,
    isEditable,
    isDeletable,
    labelPrintingDetails,
    handleIngredientList,
    labelPrintingRecipeChangeHandler,
    labelPrintingRecipeEditChangeHandler,
  } = useLabelPrinting({ isList: true, isLabelprintingList: true });
  const navigate = useNavigate();
  const [state, setState] = useState();
  const columnHelper = createColumnHelper();
  const handleDeleteAndClose = (type, e, itemName) => {
    setState(itemName);
    onDelete(e.id);
  };
  const columns = [
    columnHelper.accessor("item_name", {
      header: () => "Item Name",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack direction="row" gap={1} alignItems="center">
            {row?.original?.type_id === 1 && (
              <img src={book} alt="Book" style={{ marginRight: 8 }} />
            )}
            {row?.original?.type_id === 2 && (
              <img src={leaf} alt="Leaf" style={{ marginRight: 8 }} />
            )}
            {row?.original?.type_id === 3 && (
              <img src={Others} alt="Others" style={{ marginRight: 8 }} />
            )}
            {row?.original?.type_id === 3 ? (
              <Typography fontSize="13px" fontWeight={400}>
                {row.original.item_name}
              </Typography>
            ) : (
              <Typography
                fontSize="13px"
                fontWeight={400}
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                  color: "black",
                }}
                onClick={() =>
                  row?.original?.type_id === 2
                    ? navigate(`/ingredients/details/${row.original.item_id}`)
                    : navigate(
                        `/recipe/details/${row.original.item_id}/?basic-details`
                      )
                }
              >
                {row.original.item_name}
              </Typography>
            )}
          </Stack>
        );
      },
    }),
    columnHelper.accessor("variant_name", {
      header: () => "Variant Name",
    }),
    columnHelper.accessor("type_name", {
      header: () => "Type",
    }),
    columnHelper.accessor("defrosting_time", {
      header: () => "Defrosting Time Hrs",
      cell: (props) => {
        const { row } = props;
        return convertStringToTime(row.original.defrosting_time, "minute");
      },
    }),
    columnHelper.accessor("freezer_life", {
      header: () => "Coolroom/Freezer Life Hrs",
      cell: (props) => {
        const { row } = props;
        return convertStringToTime(row.original.freezer_life, "minute");
      },
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { row } = props;
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            mr={-1}
          >
            <SimpleMenu
              row={row}
              editAction={(e) => handleCreateOrEditModal(true, e.id)}
              handleDeleteModal={(e) =>
                handleDeleteAndClose(false, e, row.original.item_name)
              }
              isEditable={isEditable}
              isDeletable={isDeletable}
              state={labelPrinting}
            />
          </Stack>
        );
      },
    }),
  ];
  const breadcrumbs = [
    <Typography key="3" sx={{ fontSize: "14px", fontWeight: 400 }}>
      Label Printing
    </Typography>,
  ];
  const optionTypes =
    globalData?.labelPrinting_types?.map((type) => ({
      id: type.value,
      name: type.label,
    })) || [];
  return (
    <>
      {validator.current.purgeFields()}
      <PageListLayout
        breadcrumbs={breadcrumbs}
        pageContent={
          <>
            <Stack sx={{ borderRadius: "4px", bgcolor: "#fff" }}>
              <ServerPaginatedtable
                defaultData={listQuery.data?.label_printing_items || []}
                columns={columns}
                headerText="Label Printing"
                search={search}
                sorting={sorting}
                pagination={pagination}
                setSorting={(callback) =>
                  setSorting({ tableName: moduleName, callback })
                }
                setPagination={(callback) =>
                  setPagination({ tableName: moduleName, callback })
                }
                paginationProps={
                  listQuery.data?.pagination || { total: 0, lastPage: 1 }
                }
                onSearchChange={onSearchHandler}
                isLoading={listQuery?.isLoading}
                tableHeaderContent={
                  <>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        gap: "6px",
                        width: "100%",
                      }}
                    >
                      <InputControl
                        type="dropdown"
                        variant="filter"
                        size="small"
                        options={optionTypes ?? []}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        sx={{
                          width: "180px",
                          height: "36px",
                        }}
                        value={
                          selectType?.typeOptions?.find(
                            (type) =>
                              type?.id ===
                              tableData?.labelPrinting?.data?.selectedType
                          ) || null
                        }
                        onChange={(event, option) =>
                          selectType.typeValueChangeHandler(option)
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Type" />
                        )}
                      />

                      <Button
                        type="button"
                        size="small"
                        variant="text"
                        sx={{
                          p: "6px 12px 6px 12px",
                          gap: "6px",
                        }}
                        disabled={disableclearButton}
                        onClick={onClearFilter}
                      >
                        Clear All
                      </Button>
                    </Stack>
                  </>
                }
                rightContent={
                  <Stack direction="row" gap="8px">
                    {isCreateVisible && (
                      <Button
                        sx={{
                          height: "36px",
                          border: "1px solid #BCC7DB",
                          borderRadius: "10px",
                        }}
                        type="button"
                        size="medium"
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => handleCreateOrEditModal(true)}
                      >
                        Add
                      </Button>
                    )}
                  </Stack>
                }
              />
            </Stack>
          </>
        }
      />
      {labelPrinting?.id ? (
        <CustomeDialog
          open={labelPrinting.openDialog}
          PaperProps={{
            sx: { width: "482px", minHeight: "400px", p: "16px" },
          }}
          title={labelPrinting.id ? "Edit Label" : "Create Label"}
          content={
            <RecipeLabelCreateOrEdit
              handleCreateOrEditModal={handleCreateOrEditModal}
              basicDetailsQuery={basicDetailsQuery}
              onCreateSubmit={onCreateSubmit}
              onChange={onChange}
              labelPrinting={labelPrinting}
              isLabelprintingList={true}
              globalData={globalData}
              labelPrintingEditTypeChangeHandler={
                labelPrintingEditTypeChangeHandler
              }
              ingredientListQuery={ingredientListQuery}
              recipeListQuery={recipeListQuery}
              labelPrintingEditChangeHandler={labelPrintingEditChangeHandler}
              onEditChange={onEditChange}
              validator={validator}
              labelPrintingDetails={labelPrintingDetails}
              handleIngredientList={handleIngredientList}
              labelPrintingRecipeEditChangeHandler={
                labelPrintingRecipeEditChangeHandler
              }
            />
          }
        />
      ) : (
        <CustomDialogTemplate
          PaperProps={{
            sx: {
              minWidth: "1200px",
              minHeight: "550px",
            },
          }}
          title={
            <Stack direction="row" alignItems="center">
              <Typography variant="h6">Create Label</Typography>
              <Button
                type="button"
                size="small"
                variant="text"
                startIcon={<AddIcon />}
                onClick={addNewRecipeRow}
                sx={{
                  marginLeft: "960px",
                }}
              >
                Add
              </Button>
            </Stack>
          }
          closeIcon={false}
          open={labelPrinting.openDialog}
          content={
            <CreateOrEditLabelPrintingDialog
              labelPrinting={labelPrinting}
              addNewRecipeRow={addNewRecipeRow}
              handleDelete={handleDelete}
              globalData={globalData}
              labelPrintingTypeChangeHandler={labelPrintingTypeChangeHandler}
              ingredientListQuery={ingredientListQuery}
              recipeListQuery={recipeListQuery}
              onChange={onChange}
              labelPrintingChangeHandler={labelPrintingChangeHandler}
              validator={validator}
              handleIngredientList={handleIngredientList}
              labelPrintingRecipeChangeHandler={
                labelPrintingRecipeChangeHandler
              }
            />
          }
          tableFooter={
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              padding="10px"
              marginTop="auto"
            >
              <Button
                key="cancel"
                variant="outlined"
                onClick={() => handleCreateOrEditModal(false)}
              >
                Cancel
              </Button>

              <Button
                key="generate"
                variant="contained"
                onClick={() => {
                  onCreateSubmit();
                }}
                disabled={!labelPrinting?.data?.length}
              >
                Save
              </Button>
            </Stack>
          }
        />
      )}

      <LabelPrintDeleteAlertDialog
        id={deleteInfo.id}
        open={deleteInfo.show}
        handleClose={() => resetDeleteInfo()}
        onConfirm={() => confirmDelete()}
        state={state}
      />
    </>
  );
};
