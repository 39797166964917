import { Box, InputAdornment, Stack } from "@mui/material";
import React, { useRef } from "react";
import { InputControl, Loader, SelectChip } from "../../../shared";
import TemplateCustomTable from "../../Templates/components/TemplateCustomTable";
import CloseIcon from "@mui/icons-material/Close";

export const EditProductionChart = ({
  categorylistQuery,
  productionchart,
  onChangeFilter,
  onSearchChange,
  handleCustomFieldChange,
  recipelistQuery,
}) => {
  const categoryRef = useRef(null);
  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };
  return (
    <>
      <Stack direction="row" gap="10px" alignItems="center" ml={1}>
        <InputControl
          sx={{ width: "200px", borderRadius: "10px" }}
          type="table-search"
          placeholder="Search"
          onChange={(e) => onSearchChange(e.target.value)}
          value={productionchart.filter.search}
          endAdornment={
            productionchart.filter.search ? (
              <InputAdornment sx={{ cursor: "pointer" }} position="end">
                <CloseIcon size="large" onClick={() => onSearchChange("")} />
              </InputAdornment>
            ) : (
              ""
            )
          }
        />

        <Box
          height="45px"
          alignContent="center"
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, -10)
          }
        >
          <img alt="details" src="/icons/Intersect.png" />
        </Box>
        <Stack
          direction="row"
          width="725px"
          overflow="auto"
          gap={1}
          className="scroll-hide"
          ref={categoryRef}
        >
          {categorylistQuery?.data?.map((v, i) => (
            <SelectChip
              key={i}
              value={v}
              isSelected={
                productionchart.filter.category
                  ? productionchart.filter.category == v.id
                  : i === 0
              }
              onClick={() =>
                onChangeFilter(v, productionchart?.groupedDatas, i)
              }
            />
          ))}
        </Stack>
        <Box
          height="45px"
          alignContent="center"
          onClick={() =>
            handleHorizantalScroll(categoryRef.current, 50, 100, 10)
          }
        >
          <img alt="details" src="/icons/Intersect1.png" />
        </Box>
      </Stack>
      <TemplateCustomTable
        rows={productionchart?.templateResultRows}
        handleCustomFieldChange={handleCustomFieldChange}
        data={productionchart}
        recipelistQuery={recipelistQuery}
      />
    </>
  );
};
