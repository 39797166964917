import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { InputControl } from "../../../shared";
import { AsyncPaginate } from "react-select-async-paginate";

export const CreateOrEditLabelPrintingDialog = ({
  labelPrinting,
  addNewRecipeRow,
  handleDelete,
  globalData,
  labelPrintingTypeChangeHandler,
  recipeListQuery,
  onChange,
  labelPrintingChangeHandler,
  validator,
  handleIngredientList,
  labelPrintingRecipeChangeHandler,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      width: "370px",
      color: "#000000",
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000000",
      fontSize: "13px",
    }),
    option: (provided) => ({
      ...provided,
      padding: "10px 15px",
      minHeight: "50px",
    }),
    menu: (provided) => ({
      ...provided,
      position: "absolute",
      zIndex: 9999,
      marginTop: 0,
      backgroundColor: "white",
      size: "100px",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "300px",
      overflowY: "auto",
    }),
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Stack
        ref={innerRef}
        {...innerProps}
        sx={{
          flexDirection: "row",
          p: "7px 8px",
          gap: "2px",
          "&:hover": {
            backgroundColor: "#edf3fc",
          },
        }}
      >
        <Stack
          sx={{
            gap: "2px",
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={500}
            lineHeight="20px"
            fontSize="16px"
          >
            {data.label}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <Box>
      <TableContainer
        sx={{
          maxHeight: "60vh",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ height: "20px" }}>
              <TableCell>Type</TableCell>
              <TableCell>Item Name</TableCell>
              <TableCell>Variant</TableCell>
              <TableCell>Defrosting Time Hrs</TableCell>
              <TableCell>Coolroom/Freezer Life Hrs</TableCell>
              <TableCell>{}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labelPrinting?.data?.map((row, index) => (
              <>
                <TableRow
                  key={index}
                  sx={{
                    border: "1px solid #DFE8F2",
                    "& td": { padding: "8px 12px" },
                  }}
                >
                  <TableCell>
                    <InputControl
                      type="dropdown"
                      fullWidth
                      options={globalData?.labelPrinting_types ?? []}
                      value={
                        globalData?.labelPrinting_types?.find(
                          (value) => value?.value === row?.type_id
                        ) || null
                      }
                      onChange={(event, option) =>
                        labelPrintingTypeChangeHandler("type_id", option, index)
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          size="small"
                          sx={{ width: "200px" }}
                          error={validator.current.message(
                            "Type",
                            row?.type_id,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Type",
                            row?.type_id,
                            "required"
                          )}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <>
                      {row?.type_id === 3 ? (
                        <InputControl
                          type="text"
                          name="item_name"
                          sx={{
                            selfAlign: "right",
                            width: "370px",
                          }}
                          size="small"
                          value={row?.item_name}
                          onChange={(e) => {
                            onChange(e, index);
                          }}
                          error={validator.current.message(
                            "Item Name",
                            row?.item_name,
                            "required"
                          )}
                          helperText={validator.current.message(
                            "Item Name",
                            row?.item_name,
                            "required"
                          )}
                        />
                      ) : row?.type_id === 2 ? (
                        <AsyncPaginate
                          debounceTimeout={1000}
                          placeholder="Item Name *"
                          components={{
                            Option: CustomOption,
                          }}
                          value={row?.data}
                          loadOptions={async (e, p, { page }) => {
                            return await handleIngredientList({
                              search: e,
                              page,
                              type: "item_id",
                            });
                          }}
                          onChange={(e) => {
                            labelPrintingRecipeChangeHandler(
                              {
                                target: {
                                  name: "item_id",
                                  value: e.value || null,
                                },
                              },
                              e,
                              index
                            );
                          }}
                          additional={{
                            page: 1,
                          }}
                          styles={customStyles}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          menuPlacement="auto"
                        />
                      ) : (
                        <InputControl
                          type="dropdown"
                          fullWidth
                          options={recipeListQuery?.data
                            ?.slice()
                            .sort((a, b) => a.name.localeCompare(b.name))}
                          value={
                            (row?.item_id &&
                              recipeListQuery?.data?.find(
                                (value) => value.id === row?.item_id
                              )) ||
                            null
                          }
                          onChange={(event, option) =>
                            labelPrintingChangeHandler("item_id", option, index)
                          }
                          disabled={!row?.type_id}
                          getOptionLabel={(option) => option?.name || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Name"
                              size="small"
                              sx={{ width: "370px" }}
                              error={validator.current.message(
                                "Item Name",
                                row?.item_id,
                                "required"
                              )}
                              helperText={validator.current.message(
                                "Item Name",
                                row?.item_id,
                                "required"
                              )}
                            />
                          )}
                        />
                      )}
                    </>
                  </TableCell>
                  <TableCell>
                    <InputControl
                      type="text"
                      name="variant_name"
                      sx={{
                        selfAlign: "right",
                        width: "200px",
                      }}
                      size="small"
                      value={row?.variant_name}
                      onChange={(e) => {
                        onChange(e, index);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <InputControl
                      type="number"
                      name="defrosting_time"
                      sx={{
                        selfAlign: "right",
                        width: "80px",
                      }}
                      size="small"
                      onChange={(e) => {
                        onChange(e, index);
                      }}
                      value={row?.defrosting_time}
                      onFocus={(e) => {
                        if (e.target.value === "0") {
                          onChange(
                            { target: { name: "defrosting_time", value: "" } },
                            index
                          );
                        }
                      }}
                      onBlur={(e) => onChange(e, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <InputControl
                      type="number"
                      name="freezer_life"
                      sx={{
                        selfAlign: "right",
                        width: "80px",
                      }}
                      size="small"
                      onChange={(e) => {
                        onChange(e, index);
                      }}
                      value={row?.freezer_life}
                      onFocus={(e) => {
                        if (e.target.value === "0") {
                          onChange(
                            { target: { name: "freezer_life", value: "" } },
                            index
                          );
                        }
                      }}
                      onBlur={(e) => onChange(e, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <img
                      alt="delete"
                      src="/icons/ic_trash.svg"
                      onClick={() => handleDelete(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
