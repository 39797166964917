import { useImmer } from "use-immer";
import { useRef, useState, useEffect, useContext } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useDebounce } from "use-debounce";
import { useDelete, useLabelType, usePermission } from "../../../shared";
import { AppContext } from "../../../store";
import { useLabelPrintingQueries } from "./useLabelPrintingQueries";
import { convertStringToTime, convertTimeToMinute } from "../../../utils";

export const useLabelPrinting = ({
  isList,
  type_id,
  item_id,
  isLabelprintingList,
}) => {
  const isVisible = usePermission("label-printing-view");
  const isCreateVisible = usePermission("label-printing-create");
  const isEditable = usePermission("label-printing-modify");
  const isDeletable = usePermission("label-printing-delete");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();
  const moduleName = "labelPrinting";
  const {
    globalData,
    setTableState,
    tableData,
    clearAllFilters,
    setSorting,
    setPagination,
    setSearchState,
  } = useContext(AppContext);
  const { sorting, pagination, search } = tableData[moduleName];
  const [moduleSorting, setModuleSorting] = useState([]);
  const [modulePagination, setModulePagination] = useState({
    pageIndex: 1,
    pageSize: 50,
  });
  const [labelPrinting, setLabelPrinting] = useImmer({
    openDialog: false,
    id: "",
    ingredients: [],
    data: [
      {
        type_id: "",
        item_id: "",
        other_item: "",
        variant_name: "",
        defrosting_time: "00:00",
        freezer_life: "00:00",
      },
    ],
    editableData: {
      type_id: "",
      item_id: "",
      other_item: "",
      variant_name: "",
      defrosting_time: "00:00",
      freezer_life: "00:00",
      item_name: "",
    },
  });

  const [debouncedText] = useDebounce(search, 1000);
  const {
    listQuery,
    ingredientListQuery,
    recipeListQuery,
    createLabelPrinting,
    labelPrintingDetails,
    updateLabelPrinting,
    deleteLabelPrinting,
    handleIngredientList,
  } = useLabelPrintingQueries({
    isList,
    id: labelPrinting.id,
    item_id,
    pagination: type_id === 1 || type_id === 2 ? modulePagination : pagination,
    sorting,
    type_id: tableData?.labelPrinting?.data?.selectedType,
    typeId: type_id,
    debouncedText,
    resetDeleteInfo,
    moduleSorting,
    labelPrinting,
    isLabelprintingList,
    setLabelPrinting,
  });
  const onChangeTypeHandler = (value) => {
    setTableState({
      tableName: moduleName,
      key: "selectedType",
      value: value,
    });
  };
  const selectType = useLabelType({ onChangeTypeHandler });

  const handleCreateOrEditModal = (type, id, name) => {
    if (isLabelprintingList) {
      if (type === false) {
        setLabelPrinting((draft) => {
          draft.data = [
            {
              type_id: "",
            },
          ];
          draft.editableData = {
            type_id: "",
          };
        });
      }
    } else {
      setLabelPrinting((draft) => {
        draft.data = [
          {
            type_id: type_id,
          },
        ];
        draft.editableData = {
          type_id: type_id,
          item_id: item_id,
          variant_name: "",
          defrosting_time: "00:00",
          freezer_life: "00:00",
        };
      });
    }

    setLabelPrinting((draft) => {
      draft.openDialog = type;
      draft.id = id;
    });
    if (type === false) {
      setLabelPrinting((draft) => {
        draft.data = [
          {
            item_id: "",
            other_item: "",
            variant_name: "",
            defrosting_time: "00:00",
            freezer_life: "00:00",
          },
        ];
        draft.editableData = {
          item_id: "",
          other_item: "",
          variant_name: "",
          defrosting_time: "00:00",
          freezer_life: "00:00",
          item_id: "",
        };
      });
    }
  };
  const addNewRecipeRow = () => {
    const newRow = {
      type_id: "",
      item_id: "",
      other_item: "",
      variant_name: "",
      defrosting_time: "00:00",
      freezer_life: "00:00",
    };
    setLabelPrinting((draft) => {
      draft.data = [...labelPrinting.data, newRow];
    });
  };
  const handleDelete = (index) => {
    setLabelPrinting((draft) => {
      draft.data = [...draft.data.filter((data, i) => i !== index)];
    });
  };
  const labelPrintingTypeChangeHandler = (name, option, index) => {
    const id = option ? option.value : "";
    setLabelPrinting((draft) => {
      draft.data[index][name] = id;
      draft.data[index].item_id = null;
      draft.data[index].item_name = null;
    });
  };
  const labelPrintingEditTypeChangeHandler = (name, option) => {
    const id = option ? option.value : "";
    setLabelPrinting((draft) => {
      draft.editableData[name] = id;
      draft.editableData.item_id = null;
      draft.editableData.item_name = null;
    });
  };
  const labelPrintingChangeHandler = (name, option, index) => {
    const id = option ? option.id : "";
    setLabelPrinting((draft) => {
      draft.data[index][name] = id;
    });
  };
  const labelPrintingRecipeChangeHandler = (e, label, index) => {
    const { value, name } = e.target;
    setLabelPrinting((draft) => {
      draft.data[index][name] = value;
      draft.data[index].item_name = label.label;
    });
  };
  const labelPrintingEditChangeHandler = (e, label) => {
    const { value, name } = e.target;
    setLabelPrinting((draft) => {
      draft.editableData[name] = value;
      draft.editableData.item_name = label.label;
    });
  };
  const labelPrintingRecipeEditChangeHandler = (name, option, index) => {
    const id = option ? option.id : "";
    setLabelPrinting((draft) => {
      draft.editableData[name] = id;
    });
  };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    if (
      (name === "defrosting_time" || name === "freezer_life") &&
      e.type === "blur"
    ) {
      setLabelPrinting((draft) => {
        draft.data[index][name] = convertStringToTime(value);
      });
    } else {
      setLabelPrinting((draft) => {
        draft.data[index][name] = value;
      });
    }
  };
  const onEditChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === "defrosting_time" || name === "freezer_life") &&
      e.type === "blur"
    ) {
      setLabelPrinting((draft) => {
        draft.editableData[name] = convertStringToTime(value);
      });
    } else {
      setLabelPrinting((draft) => {
        draft.editableData[e.target.name] = e.target.value;
      });
    }
  };

  const onCreateSubmit = () => {
    if (validator.current.allValid()) {
      let editableData = {
        id: labelPrinting.editableData.id,
        type_name: labelPrinting.editableData.type_name,
        type_id: type_id ? type_id : labelPrinting.editableData.type_id,
        item_name: labelPrinting.editableData.item_name,
        variant_name: labelPrinting.editableData.variant_name,
        defrosting_time: convertTimeToMinute(
          labelPrinting.editableData.defrosting_time
        ),
        freezer_life: convertTimeToMinute(
          labelPrinting.editableData.freezer_life
        ),
        other_item: labelPrinting.editableData.item_name,
        item_id: item_id ? item_id : labelPrinting.editableData.item_id,
      };

      let createData = labelPrinting.data.map((val) => {
        return {
          ...val,
          defrosting_time: convertTimeToMinute(val.defrosting_time ?? "00:00"),
          freezer_life: convertTimeToMinute(val.freezer_life ?? "00:00"),
          other_item: val.item_name,
        };
      });

      let singleCreateData = [
        {
          ...labelPrinting.editableData,
          defrosting_time: convertTimeToMinute(
            labelPrinting.editableData.defrosting_time ?? "00:00"
          ),
          freezer_life: convertTimeToMinute(
            labelPrinting.editableData.freezer_life ?? "00:00"
          ),
          other_item: labelPrinting.editableData.item_name,
        },
      ];

      const payload1 = { ...editableData, id: labelPrinting.id };
      labelPrinting.id
        ? updateLabelPrinting.mutate({
            data: [payload1],
            handleCreateOrEditModal,
          })
        : createLabelPrinting.mutate({
            data: isLabelprintingList ? createData : singleCreateData,
            handleCreateOrEditModal,
          });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };
  useEffect(() => {
    if (labelPrintingDetails?.data) {
      setLabelPrinting((draft) => {
        draft.editableData = {
          ...labelPrintingDetails?.data,
          other_item: labelPrintingDetails?.data.item_name,
          defrosting_time: convertStringToTime(
            labelPrintingDetails?.data.defrosting_time,
            "minute"
          ),
          freezer_life: convertStringToTime(
            labelPrintingDetails?.data.freezer_life,
            "minute"
          ),
          item_name: labelPrintingDetails?.data.item_name,
        };
      });
    }
  }, [labelPrintingDetails.data]);
  const confirmDelete = () => {
    deleteLabelPrinting.mutate({ id: deleteInfo.id, to: deleteInfo.to });
  };
  useEffect(() => {
    setLabelPrinting((draft) => {
      draft.editableData.type_id = type_id;
      draft.editableData.item_id = item_id;
    });
  }, [item_id, type_id]);
  const onSearchHandler = (search) => {
    setSearchState({
      tableName: moduleName,
      key: "search",
      value: search,
    });
  };
  const onClearFilter = () => {
    clearAllFilters({ tableName: moduleName });
  };
  const disableclearButton =
    tableData.labelPrinting.data.selectedType == null ||
    tableData.labelPrinting.data.selectedType === ""
      ? true
      : false;

  return {
    labelPrinting,
    listQuery,
    sorting,
    pagination,
    handleCreateOrEditModal,
    addNewRecipeRow,
    handleDelete,
    globalData,
    labelPrintingTypeChangeHandler,
    ingredientListQuery,
    recipeListQuery,
    onChange,
    onCreateSubmit,
    labelPrintingChangeHandler,
    onDelete,
    confirmDelete,
    resetDeleteInfo,
    deleteInfo,
    selectType,
    clearAllFilters,
    setSorting,
    setPagination,
    setSearchState,
    moduleName,
    tableData,
    onSearchHandler,
    search,
    onClearFilter,
    disableclearButton,
    labelPrintingEditTypeChangeHandler,
    labelPrintingEditChangeHandler,
    onEditChange,
    moduleSorting,
    modulePagination,
    setModulePagination,
    setModuleSorting,
    validator,
    isCreateVisible,
    isEditable,
    isDeletable,
    isVisible,
    labelPrintingDetails,
    handleIngredientList,
    labelPrintingRecipeEditChangeHandler,
    labelPrintingRecipeChangeHandler,
  };
};
