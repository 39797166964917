import * as React from "react";
import { Typography, Stack, Grid, Box } from "@mui/material";

export const StoreListCard = (props) => {
  const { storeList, selectedStore, onSelect, productionchart } = props;
  return (
    <Grid item xs={5} md={4}>
      <Stack direction="row" justifyContent="space-between" mt={1} ml={1}>
        <Typography fontWeight={600} fontSize="12px">
          Store
        </Typography>
        <Typography fontWeight={600} fontSize="12px">
          Batches
        </Typography>
      </Stack>
      <Box
        ml={1}
        mt={1}
        sx={{
          ml: "8px",
          mt: "8px",
          borderRadius: "4px",
          overflow: "hidden",
          boxShadow: "0px 2px 4px 0px #0000000F",
          border: "1px solid #DFE8F2",
        }}
      >
        {storeList?.map((data, i) => (
          <Stack
            key={i}
            height="40px"
            bgcolor={
              selectedStore?.store_id == data?.store_id ? "red" : "#FFFFFF"
            }
            onClick={() => onSelect(data, productionchart?.dataList)}
            sx={{
              bgcolor:
                selectedStore.store_id == data.store_id ? "#1760D2" : "#FFFFFF",
              color:
                selectedStore.store_id == data.store_id ? "#FFFFFF" : "#000",
              borderBottom: `1px solid #DFE8F2`,
              "&:last-child": {
                borderBottom: "none",
              },
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Stack flexDirection="row" justifyContent="space-between">
              <Stack direction="row" gap={1} ml={1}>
                <img
                  alt="details"
                  src="/icons/ic_sm_home.png"
                  style={{
                    height: "20px",
                    filter:
                      selectedStore.store_id == data.store_id &&
                      "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(117deg) brightness(104%) contrast(103%)",
                  }}
                />
                <Typography
                  fontWeight={400}
                  fontSize="14px"
                  alignContent="center"
                >
                  {data.store_name}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                alignContent="center"
                mr={1}
              >
                <Box
                  borderRadius="24px"
                  bgcolor={
                    selectedStore.store_id == data.store_id
                      ? "#00000033"
                      : "#DFE8F2"
                  }
                  height="20px"
                  width="20px"
                  alignContent="center"
                  textAlign="center"
                >
                  <Typography fontSize="12px" fontWeight={500}>
                    {data.batches.length}
                  </Typography>
                </Box>

                <img
                  alt="details"
                  src="/icons/arrow_forward.png"
                  style={{
                    height: "10px",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Box>
    </Grid>
  );
};
